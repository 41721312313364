import {
  Core_SowAsset,
  Core_SowAssetMedia,
  Core_SowItem,
  Core_SowItemSolution,
  Core_SowMedia,
  Core_SowProject,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { RouteComponentProps } from '@reach/router'

export type LabelValueOption = {
  label: string
  value: string
}

export const uomOptions = ['EA', 'LS', 'LF', 'SF', 'TON', 'SY', 'SQ']

export const urgencyOptions = [
  {
    label: 'H&S',
    value: 'health_and_safety',
  },
  {
    label: '1st Turn',
    value: 'first_turn',
  },
  {
    label: '0-3 Years',
    value: 'zero_to_three_years',
  },
  {
    label: 'Non Imm.',
    value: 'non_immediately_addressable',
  },
]

export const adjustmentOptions = [
  {
    label: '100%',
    value: 1,
  },
  {
    label: '75%',
    value: 0.75,
  },
  {
    label: '50%',
    value: 0.5,
  },
]

export type SowCostCatalog = {
  [key: string]: SowCostCatalogItem[]
}

export type SowCostCatalogItem = {
  itemCode: string
  itemName: string
  itemNotes: string
  uom: string
  price: number
  category: string
}

export type SowMedia = {
  uuid: string
  s3Url: string
}

export type SowItemSolution = {
  uuid: string
  category: string
  solution: string
  description: string
  unitOfMeasurement: string
  unitPrice: number
  quantity: number
  urgency: string
  adjustment: number
  itemCode: string
}

export type SowItemDeficiency = {
  uuid: string
  status: string
  location: string
  category: string
  deficiency: string
  media: SowMedia[]
  reviewerNotes: string
  solutions: SowItemSolution[]
}

export type SowAsset = {
  uuid: string
  sowProjectUuid: string
  status: string
  location: string
  category: string
  item: string
  month: number
  year: number
  media: SowMedia[]
  endOfLifeDate: Date
}

// Represents an individual project
export type SowProject = {
  uuid: string
  projectName: string
  projectStatus: string
  formattedAddress: string
  yearBuilt: number
  isMultifamily: boolean

  beds: number
  baths: number
  squareFootage: number
  inspectionDate: Date
  deficiencies: SowItemDeficiency[]
  assets: SowAsset[]

  salesforceBeds: number
  salesforceBaths: number
  salesforceSquareFootage: number
  salesforceYearBuilt: number
}

export const convertGqlSowMediaToSowMedia = (
  mediaToConvert: Core_SowMedia | Core_SowAssetMedia
) => ({
  uuid: mediaToConvert.uuid,
  s3Url: mediaToConvert.s3Url,
})

export const convertGqlSowAssetToSowAsset = (
  assetToConvert: Core_SowAsset
) => ({
  uuid: assetToConvert.uuid,
  sowProjectUuid: assetToConvert.sowProjectUuid,
  status: assetToConvert.status,
  location: assetToConvert.assetLocation,
  category: assetToConvert.assetCategory,
  item: assetToConvert.item,
  month: assetToConvert.assetMonth,
  year: assetToConvert.assetYear,
  endOfLifeDate: new Date(assetToConvert.endOfLifeDate),
  media: assetToConvert.sowAssetMedia.map(convertGqlSowMediaToSowMedia),
})

export const convertGqlSowSolutionToSowSolution = (
  solutionToConvert: Core_SowItemSolution
) => ({
  uuid: solutionToConvert.uuid,
  category: solutionToConvert.category,
  solution: solutionToConvert.solution,
  description: solutionToConvert.itemSolutionDescription,
  unitOfMeasurement: solutionToConvert.unitOfMeasurement,
  unitPrice: solutionToConvert.unitCost,
  quantity: solutionToConvert.quantity,
  urgency: solutionToConvert.acquisitionScopeCategory,
  adjustment: solutionToConvert.adjustment,
  itemCode: solutionToConvert.costbookItemCode,
})

export const convertGqlSowDeficiencyToSowDeficiency = (
  deficiencyToConvert: Core_SowItem
) => ({
  uuid: deficiencyToConvert.uuid,
  status: deficiencyToConvert.itemStatus,
  location: deficiencyToConvert.itemLocation,
  category: deficiencyToConvert.category,
  deficiency: deficiencyToConvert.deficiency,
  media: deficiencyToConvert.sowMedia.map(convertGqlSowMediaToSowMedia),
  reviewerNotes: deficiencyToConvert.reviewerNotes,
  solutions: deficiencyToConvert.sowItemSolutions.map(
    convertGqlSowSolutionToSowSolution
  ),
})

export const convertGqlSowProjectToSowProject = (
  projectToConvert: Core_SowProject
): SowProject => ({
  uuid: projectToConvert.uuid,
  projectName: projectToConvert.projectName,
  projectStatus: projectToConvert.projectStatus as string,
  formattedAddress: projectToConvert?.address?.formattedAddress as string,
  yearBuilt: projectToConvert.yearBuilt,
  isMultifamily: projectToConvert.isMultifamily,
  beds: projectToConvert.numBeds,
  baths: projectToConvert.numBaths,
  squareFootage: projectToConvert.numSqft,
  inspectionDate: projectToConvert.inspectionDate,
  deficiencies: projectToConvert.sowItemsInfo.map(
    convertGqlSowDeficiencyToSowDeficiency
  ),
  assets: projectToConvert.sowAssets.map(convertGqlSowAssetToSowAsset),
  salesforceBeds: projectToConvert.address?.beds as number,
  salesforceBaths: projectToConvert.address?.baths as number,
  salesforceSquareFootage: projectToConvert.address?.sqft as number,
  salesforceYearBuilt: projectToConvert.address?.yearBuilt as number,
})

export type SowProjectComputedTotals = {
  healthAndSafetyTotal: number
  firstTurnTotal: number
  zeroToThreeYearsTotal: number
  nonImmediatelyAddressableTotal: number

  healthAndSafetyTotalWithExteriorAllocation: number
  firstTurnTotalWithExteriorAllocation: number
  zeroToThreeYearsTotalWithExteriorAllocation: number
  nonImmediatelyAddressableTotalWithExteriorAllocation: number

  interiorTotal: number
  exteriorTotal: number
  overallTotal: number
}

export type IndividualSowProjectPageProps = RouteComponentProps

export type IndividualSowProjectPagePresentationalProps =
  IndividualSowProjectPageProps & {
    formattedAddress: string
    beds: number
    baths: number
    squareFootage: number
    yearBuilt: number

    editModalOpen: boolean
    setEditModalOpen: (open: boolean) => void

    inspectionDate: string

    sowProjects: SowProject[]
    selectedProject: number
    setSelectedProject: (index: number) => void

    showAddUnit: boolean
    setShowAddUnit: (show: boolean) => void

    expandedAll: boolean
    setExpandedAll: (expanded: boolean) => void

    projectDeficiencies: SowItemDeficiency[]
    openEditDeficiency: (deficiencyUuid: string) => void

    costCatalog: SowCostCatalog
    locationOptions: string[]
    categoryOptions: string[]

    projectAssets: SowAsset[]
    openEditAsset: (assetUuid: string) => void

    computedTotals: SowProjectComputedTotals[]
    interiorTotal: number
    exteriorTotal: number
    overallTotal: number

    showEditDeficiency: boolean
    selectedDeficiency: SowItemDeficiency | undefined
    onCloseEditDeficiency: () => void

    showEditAsset: boolean
    selectedAsset: SowAsset | undefined
    onCloseEditAsset: () => void

    exportModalOpen: boolean
    setExportModalOpen: (open: boolean) => void

    unitHealthAndSafetyTotal: number
    unitFirstTurnTotal: number
    unitZeroToThreeYearsTotal: number
    unitNonImmediatelyAddressableTotal: number
    unitImmediatelyAddressableTotal: number

    propertyHealthAndSafetyTotal: number
    propertyFirstTurnTotal: number
    propertyZeroToThreeYearsTotal: number
    propertyImmediatelyAddressableTotal: number
    propertyNonImmediatelyAddressableTotal: number
    propertyTotal: number

    loading: boolean
    refetch: () => Promise<any>
    sync: () => Promise<void>
    syncLoading: boolean

    newUnitOptions: LabelValueOption[]

    costbookUuid: string
    parentProjectType: string
    parentProjectId: string

    deficiencySortBy: string
    setDeficiencySortBy: (sortBy: string) => void
  }
