import { gql, useMutation, useQuery } from '@apollo/client'
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  ReactNode,
} from 'react'

import { AnyInputConfig, InputType, useSnackbar } from '@flock/shared-ui'
import { useParams } from '@reach/router'
import { Box, Button, Divider, Typography } from '@mui/material'
import {
  AdminAddressValuationsGetSingleFamilyAddressValuationDocument,
  AdminAddressValuationsGetMarketRentCompsForAddressValuationDocument,
  AdminAddressValuationsGetOfferPriceCompsForAddressValuationDocument,
  Core_ValuationType,
  Core_CompSource,
  Core_ValuationV1,
  Core_ComputeSalesforceAddressValuationRequestInput,
  Core_Property,
  Core_SalesforceVerifiedProperty,
  Core_MarketRentCompInput,
  Core_OfferValuationCompInput,
  AdminAddressValuationsGetAddressValuationHistoryDocument,
  Core_ValuationCategory,
  AdminAddressValuationsComputeSalesforceAddressValuationDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { formatIntegerDollars, formatPercentage } from '@flock/utils'
import {
  RentalCompFormInput,
  RentalCompPrefillFormInput,
  OfferCompFormInput,
  OfferCompPrefillFormInput,
  SingleFamilyComputeAddressValuationTabProps,
} from './singleFamilyComputeAddressValuationTabTypes'
import {
  getCompsValidationErrorMessage,
  supervisorUuids,
  finalSubmitUuids,
  getConditionScoreFromCondition,
  GetUiValuationType,
} from '../valuationUtils'

export const GET_SINGLE_FAMILY_ADDRESS_VALUATION = gql`
  fragment ValuationTrailFields on Core_ValuationTrailNode {
    name
    description
    value
  }
  query AdminAddressValuationsGetSingleFamilyAddressValuation(
    $input: Core_GetSingleFamilyAddressValuationRequestInput!
  ) {
    getSingleFamilyAddressValuation(input: $input) {
      valuation {
        uuid
        type
        finalOfferPrice
        expiresAt
        updatedAt
        inputs {
          offerPrice {
            comps {
              source
              similarityScore
              url
              avmPrice
              squareFootage
              estimatedRemodelCost
              conditionScore
              address
              beds
              baths
              bathsDouble
              halfBaths
              yearBuild
              soldDate
              notes
              flatAvmAdjustment
            }
            housecanaryAdjustedAvmPrice
            notes
            mortgageAmount
            cashTakeout
            cashToClose
            selfReportedValue
            useCma
            propertyFlatAvmAdjustment
          }
          remodelCost {
            score
            cost
            daysInRemodel
            daysInRemodelDeduction
            sowCost
            healthAndSafetyCapex
            immediateAddressableCapex
            totalAddressableCapex
            notes
          }
          marketRent {
            housecanaryProjectedRent
            comps {
              source
              marketRent
              rentalDate
              squareFootage
              url
              address
              notes
            }
            analystProjectedRent
            notes
            currentlyOccupied
            currentRent
            monthsRemainingOnLease
          }
          otherCosts {
            acquisition
            miscellaneous
            hoa
            propertyTaxes
            notes
            addressNearbyHomeHoa
            urlNearbyHomeHoa
            hoaExists
            inPlacePropertyTaxes
          }
          contributionMonth
          isSectionEight
        }
        outputs {
          impliedYieldMinimums
          msaLevelMinimums
          finalOfferPrice
          netYieldAdjustedOfferPrice
          inPlaceNetYield
          grossYieldOnAdjustedOfferPrice
          grossYield
          capRate
          netYield
          noiMarginAverage
          pass
          submarketRentDeduction
          daysInRemodelDeduction
          unconstrainedMsaYieldMin
          unconstrainedMsaYieldMinOfferPrice
          uwCashOnCashYield {
            customerUwCashOnCashYield
            currentUwCashOnCashYield
          }
          remodelCosts {
            healthAndSafetyCapex
            immediateAddressableCapex
            totalAddressableCapex
          }
        }
        property {
          sqft
          yearBuilt
        }
        rejectionReason
        operatorUuid
        valuationTrail {
          ...ValuationTrailFields
          nodes {
            ...ValuationTrailFields
            nodes {
              ...ValuationTrailFields
              nodes {
                ...ValuationTrailFields
                nodes {
                  ...ValuationTrailFields
                  nodes {
                    ...ValuationTrailFields
                    nodes {
                      ...ValuationTrailFields
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const COMPUTE_SALESFORCE_ADDRESS_VALUATION = gql`
  fragment ValuationTrailFields on Core_ValuationTrailNode {
    name
    description
    value
  }
  mutation AdminAddressValuationsComputeSalesforceAddressValuation(
    $computeValuationInput: Core_ComputeSalesforceAddressValuationRequestInput!
  ) {
    computeSalesforceAddressValuation(input: $computeValuationInput) {
      valuation {
        addressId
        type
        inputs {
          remodelCost {
            daysInRemodelDeduction
            sowCost
            healthAndSafetyCapex
            immediateAddressableCapex
            totalAddressableCapex
            cost
          }
          marketRent {
            analystProjectedRent
            currentlyOccupied
            currentRent
            monthsRemainingOnLease
          }
          offerPrice {
            comps {
              source
              similarityScore
              url
              avmPrice
              squareFootage
              estimatedRemodelCost
              conditionScore
              address
              beds
              baths
              bathsDouble
              halfBaths
              yearBuild
              soldDate
              notes
              flatAvmAdjustment
            }
            housecanaryAdjustedAvmPrice
            notes
            cashToClose
            mortgageAmount
            cashTakeout
            selfReportedValue
            useCma
          }
          otherCosts {
            propertyTaxes
            hoa
            inPlacePropertyTaxes
          }
        }
        outputs {
          finalOfferPrice
          inPlaceNetYield
          impliedYieldMinimums
          msaLevelMinimums
          netYieldAdjustedOfferPrice
          grossYieldOnAdjustedOfferPrice
          grossYield
          netYield
          noiMarginAverage
          pass
          submarketRentDeduction
          unconstrainedMsaYieldMin
          unconstrainedMsaYieldMinOfferPrice
          uwCashOnCashYield {
            customerUwCashOnCashYield
            currentUwCashOnCashYield
          }
          daysInRemodelDeduction
          remodelCosts {
            healthAndSafetyCapex
            immediateAddressableCapex
            totalAddressableCapex
          }
          insuranceExpense
        }
        operatorUuid
        valuationTrail {
          ...ValuationTrailFields
          nodes {
            ...ValuationTrailFields
            nodes {
              ...ValuationTrailFields
              nodes {
                ...ValuationTrailFields
                nodes {
                  ...ValuationTrailFields
                  nodes {
                    ...ValuationTrailFields
                    nodes {
                      ...ValuationTrailFields
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_MARKET_RENT_COMPS_FOR_VALUATION = gql`
  query AdminAddressValuationsGetMarketRentCompsForAddressValuation(
    $input: Core_GetMarketRentCompsForAddressValuationRequestInput!
  ) {
    getMarketRentCompsForAddressValuation(input: $input) {
      comps {
        source
        marketRent
        url
        address
        notes
        squareFootage
        rentalDate
        uuid
        addressUuid
        selected
        updatedAt
        distance
      }
    }
  }
`

export const GET_OFFER_COMPS_FOR_VALUATION = gql`
  query AdminAddressValuationsGetOfferPriceCompsForAddressValuation(
    $input: Core_GetOfferPriceCompsForAddressValuationRequestInput!
  ) {
    getOfferPriceCompsForAddressValuation(input: $input) {
      comps {
        source
        similarityScore
        url
        avmPrice
        squareFootage
        address
        beds
        baths
        bathsDouble
        halfBaths
        yearBuild
        notes
        estimatedRemodelCost
        conditionScore
        soldDate
        uuid
        addressUuid
        selected
        updatedAt
        distance
        flatAvmAdjustment
      }
    }
  }
`

const remodelCostMap: { [key: number]: number } = {
  5: 3,
  4: 7,
  3: 12,
  2: 25,
  1: 40,
  0: 0,
}

const oldRemodelCostMap: { [key: number]: number } = {
  1500: 5,
  6500: 4,
  15000: 3,
  27500: 2,
  50000: 1,
  0: 0,
}

const useSingleFamilyComputeAddressValuationTab = (
  props: SingleFamilyComputeAddressValuationTabProps
) => {
  const {
    operatorData,
    addressData,
    valuationHistory,
    valuationHistoryLoading,
  } = props
  let { addressId } = useParams()
  addressId = addressId as string
  const { notify } = useSnackbar()
  const [loading, setLoading] = useState(true)
  // @ts-ignore
  const [selfReportedValue, setSelfReportedValue] = useState(0) // eslint-disable-line @typescript-eslint/no-unused-vars
  // @ts-ignore
  const [monthsRemainingOnLease, setMonthsRemainingOnLease] = useState(0) // eslint-disable-line @typescript-eslint/no-unused-vars
  const [computedValuationResult, setComputedValuationResult] =
    useState<Core_ValuationV1>()
  const [previousValuation, setPreviousValuation] = useState<Core_ValuationV1>()
  const [hasSubmittedInitialValuation, setHasSubmittedInitialValuation] =
    useState(false)
  // we want to use 1 month away - rounded up -> so we add 2 months and use the month value
  const contributionMonth = useMemo(() => {
    const tempMonth = new Date(new Date().setMonth(new Date().getMonth() + 2))
    tempMonth.setDate(1)
    return tempMonth
  }, [])

  const [valuationHistoryModalOpen, setValuationHistoryModalOpen] =
    useState(false)
  const [rejectValuationModalOpen, setRejectValuationModalOpen] =
    useState(false)
  const [fetchRentalCompsModalOpen, setFetchRentalCompsModalOpen] =
    useState(false)
  const [fetchOfferCompsModalOpen, setFetchOfferCompsModalOpen] =
    useState(false)
  const [valuationInputs, setValuationInputs] =
    useState<Core_ComputeSalesforceAddressValuationRequestInput>()

  const [numberPrefillRentalComps, setNumberPrefillRentalComps] = useState(0)
  const [numberPrefillOfferComps, setNumberPrefillOfferComps] = useState(0)
  // These maps prefilled address to their respective uuids, so we can pass it back into the compute valuation request
  const [rentalCompAddressUuidMap, setRentalCompAddressUuidMap] = useState<
    Map<string, string>
  >(new Map())
  const [offerCompAddressUuidMap, setOfferCompAddressUuidMap] = useState<
    Map<string, string>
  >(new Map())

  // these are for legacy comps -> i.e. from the valuation details json we fall back to if none exist in the comps table.
  const [legacyRentalCompsPrefill, setLegacyRentalCompsPrefill] = useState<
    any[]
  >([])
  const [legacyOfferCompsPrefill, setLegacyOfferCompsPrefill] = useState<any[]>(
    []
  )
  const [prefilledRentalComps, setPrefilledRentalComps] =
    useState<Core_MarketRentCompInput[]>()
  const [prefilledOfferComps, setPrefilledOfferComps] =
    useState<Core_OfferValuationCompInput[]>()

  const [offerCompsErrorMessage, setOfferCompsErrorMessage] = useState('')
  const [rentCompsErrorMessage, setRentCompsErrorMessage] = useState('')

  const [offerCompsOutOfDateMessage, setOfferCompsOutOfDateMessage] =
    useState<ReactNode>('')
  const [rentCompsOutOfDateMessage, setRentCompsOutOfDateMessage] =
    useState<ReactNode>('')

  const [injectedFormValues, setInjectedFormValues] = useState<{
    [key: string]: any
  }>({})

  const [
    analystProjectedRentPerSqFtVsRentalCompAverageRentPerSqFt,
    setAnalystProjectedRentPerSqFtVsRentalCompAverage,
  ] = useState(0)
  const [
    analystProjectedRentVsHousecanaryProjectedRent,
    setAnalystProjectedRentVsHousecanaryProjectedRent,
  ] = useState(0)
  const [offerPriceValue, setOfferPriceValue] = useState(0)
  const [compBasisPerSqFt, setCompBasisPerSqFt] = useState(0)
  const [costBasisPerSqFt, setCostBasisPerSqFt] = useState(0)
  const [valuationType, setValuationType] = useState('')
  const [currentlyOccupied, setCurrentlyOccupied] = useState(false)
  const [useCma, setUseCma] = useState(false)
  // the idea here is that the only time we should call "fetchAllCompsForValuation" is on the initial getvaluationv1 call and only if it succeeds.
  // if we prefill after computeValuation call -> there could be comps in the manual address input section that end up appearing again in the prefill section
  const [shouldPrefillCompsFromDb, setShouldPrefillCompsFromDb] = useState(true)

  const { refetch: fetchMarketRentCompsForValuation } = useQuery(
    AdminAddressValuationsGetMarketRentCompsForAddressValuationDocument,
    {
      skip: true,
    }
  )
  const { refetch: fetchOfferPriceCompsForValuation } = useQuery(
    AdminAddressValuationsGetOfferPriceCompsForAddressValuationDocument,
    {
      skip: true,
    }
  )

  // gets prefilled fields for the form from salesforce data
  const getPrefilledValuationFieldsFromSalesforce = useCallback(
    (
      address: Core_SalesforceVerifiedProperty | null | undefined,
      prefilledValuationInputs: Core_ComputeSalesforceAddressValuationRequestInput
    ) => {
      let newPrefilledValuationInputs = { ...prefilledValuationInputs }
      newPrefilledValuationInputs = {
        ...newPrefilledValuationInputs,
        remodelCost: {
          score: getConditionScoreFromCondition(address?.propertyCondition),
        },
        property: {
          ...newPrefilledValuationInputs.property,
          propertyType: address?.propertyType,
          sqft: address?.sqft,
          beds: address?.beds,
          baths: address?.baths,
          bathsDouble: address?.baths,
          yearBuilt: address?.yearBuilt || 1980,
        },
      }
      return newPrefilledValuationInputs
    },
    []
  )

  // this method is only used to parse existing old json rental comps
  // from valuation details json if there are no selected comps from the comps table
  const parseExistingJsonRentalComps = (
    comps: Core_MarketRentCompInput[] | null | undefined
  ) => {
    if (comps) {
      const legacyMarketRentCompsPrefill = Object.assign(
        {},
        ...(comps.map((comp: Core_MarketRentCompInput, idx: number) => ({
          [`rentCompSource${idx}`]: comp.source,
          [`rentCompMarketRent${idx}`]: comp.marketRent,
          [`rentCompRentalDate${idx}`]: comp.rentalDate,
          [`rentCompSquareFootage${idx}`]: comp.squareFootage,
          [`rentCompUrl${idx}`]: comp.url,
          [`rentCompAddress${idx}`]: comp.address,
          [`rentCompNotes${idx}`]: comp.notes,
          [`rentCompSelected${idx}`]: true,
        })) || [])
      )
      setLegacyRentalCompsPrefill(legacyMarketRentCompsPrefill)
    }
  }

  const parseExistingJsonOfferComps = (
    comps: Core_OfferValuationCompInput[] | null | undefined
  ) => {
    if (comps) {
      const legacyOfferPriceCompsPrefill = Object.assign(
        {},
        ...(comps.map((comp: Core_OfferValuationCompInput, idx: number) => ({
          [`offerCompSource${idx}`]: comp.source,
          [`similarityScore${idx}`]: comp.similarityScore,
          [`offerCompUrl${idx}`]: comp.url,
          [`avmPrice${idx}`]: comp.avmPrice,
          [`squareFootage${idx}`]: comp.squareFootage,
          [`offerCompAddress${idx}`]: comp.address,
          [`beds${idx}`]: comp.beds,
          [`baths${idx}`]: comp.baths,
          [`bathsDouble${idx}`]: comp.baths,
          [`halfBaths${idx}`]: comp.halfBaths,
          [`yearBuilt${idx}`]: comp.yearBuild,
          [`offerCompSoldDate${idx}`]: comp.soldDate,
          [`offerCompNotes${idx}`]: comp.notes,
          [`offerCompSelected${idx}`]: true,
          [`conditionScore${idx}`]:
            comp.conditionScore ||
            oldRemodelCostMap[comp.estimatedRemodelCost || 15000],
        })) || [])
      )
      setLegacyOfferCompsPrefill(legacyOfferPriceCompsPrefill)
    }
  }

  // sorts comps for displaying in the prefilled inputs
  // show selected comps first, then order by updated at (most recently updated first)
  const compsSort = useCallback(
    (
      a: Core_OfferValuationCompInput | Core_MarketRentCompInput,
      b: Core_OfferValuationCompInput | Core_MarketRentCompInput
    ) => {
      if (a.selected && !b.selected) {
        return -1
      } else if (!a.selected && b.selected) {
        return 1
      } else if (a.distance && b.distance && a.distance !== b.distance) {
        return a.distance < b.distance ? -1 : 1
      }
      // this is called by combineAndSortComps which should only be called
      // when fetching comps from the db, so all comps should have addresses
      // so the return 0 should never get hit.
      else if (a.address && b.address) {
        return a.address < b.address ? -1 : 1
      } else {
        return 0
      }
    },
    []
  )

  // this method combines comps that we have already prefilled
  // with comps that we are fetching from another api call
  const combineAndSortComps = useCallback(
    (
      existingComps:
        | Core_MarketRentCompInput[]
        | Core_OfferValuationCompInput[]
        | null
        | undefined,
      newComps: Core_MarketRentCompInput[] | Core_OfferValuationCompInput[]
    ) => {
      const combinedComps = []
      if (existingComps && existingComps.length > 0) {
        combinedComps.push(...existingComps)
      }
      // add new comps
      for (let i = 0; i < newComps.length; i += 1) {
        // if comp exists already in array we replace, otherwise add
        const comp = newComps[i]
        const existingCompIndex = combinedComps.findIndex(
          (combinedComp) => combinedComp.address === comp.address
        )
        if (existingCompIndex !== -1) {
          combinedComps[existingCompIndex] = comp
        } else {
          combinedComps.push(comp)
        }
      }
      combinedComps.sort(compsSort)
      return combinedComps
    },
    [compsSort]
  )

  const parseRentalCompsForPrefill = useCallback(
    (comps: Core_MarketRentCompInput[] | null | undefined) => {
      if (comps) {
        // sort comps by selected first
        let sortedComps = combineAndSortComps(prefilledRentalComps, comps)
        const compsLength = Math.min(sortedComps.length, 10) // we take at most 10 comps
        sortedComps = sortedComps.slice(0, compsLength)
        const marketRentCompsPrefill = Object.assign(
          // how gridForm sets numForms is okay if we fetch the comps before loading the form
          // but since we don't reset the form on inputConfig changes, change we need to inject the
          // numForms like such
          { marketRentCompsPrefillnumForms: sortedComps.length },
          ...(sortedComps.map(
            (comp: Core_MarketRentCompInput, idx: number) => ({
              [`rentCompSourcePrefill${idx}`]: comp.source,
              [`rentCompMarketRentPrefill${idx}`]: comp.marketRent,
              [`rentCompRentalDatePrefill${idx}`]: comp.rentalDate,
              [`rentCompSquareFootagePrefill${idx}`]: comp.squareFootage,
              [`rentCompUrlPrefill${idx}`]: comp.url,
              [`rentCompAddressPrefill${idx}`]: comp.address,
              [`rentCompNotesPrefill${idx}`]: comp.notes,
              [`rentCompSelectedPrefill${idx}`]: comp.selected,
              [`rentCompDistancePrefill${idx}`]: comp.distance,
            })
          ) || [])
        )
        const addressUuidMap = new Map(
          sortedComps.map((comp: Core_MarketRentCompInput) => [
            comp.address!,
            comp.addressUuid!,
          ])
        )
        setRentalCompAddressUuidMap(addressUuidMap)
        setNumberPrefillRentalComps(sortedComps.length)
        setPrefilledRentalComps(sortedComps)
        setInjectedFormValues(marketRentCompsPrefill)
      }
    },
    // JSON.stringify prevents infinite loop, but it still warns so ignore it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [combineAndSortComps, JSON.stringify(prefilledRentalComps)]
  )

  const parseOfferCompsForPrefill = useCallback(
    (comps: Core_OfferValuationCompInput[] | null | undefined) => {
      if (comps) {
        let sortedComps = combineAndSortComps(prefilledOfferComps, comps)
        const compsLength = Math.min(sortedComps.length, 10) // we take at most 10 comps
        sortedComps = sortedComps.slice(0, compsLength)
        const offerPriceCompsPrefill = Object.assign(
          // how gridForm sets numForms is okay if we fetch the comps before loading the form
          // but since we don't reset the form on inputConfig changes, change we need to inject the
          // numForms like such
          { offerPriceCompsPrefillnumForms: sortedComps.length },
          ...(sortedComps.map(
            (comp: Core_OfferValuationCompInput, idx: number) => ({
              [`offerCompSourcePrefill${idx}`]: comp.source,
              [`similarityScorePrefill${idx}`]: comp.similarityScore,
              [`offerCompUrlPrefill${idx}`]: comp.url,
              [`avmPricePrefill${idx}`]: comp.avmPrice,
              [`squareFootagePrefill${idx}`]: comp.squareFootage,
              [`offerCompAddressPrefill${idx}`]: comp.address,
              [`bedsPrefill${idx}`]: comp.beds,
              [`bathsPrefill${idx}`]: comp.baths,
              [`bathsDoublePrefill${idx}`]: comp.baths,
              [`halfBathsPrefill${idx}`]: comp.halfBaths,
              [`yearBuiltPrefill${idx}`]: comp.yearBuild,
              [`offerCompSoldDatePrefill${idx}`]: comp.soldDate,
              [`offerCompNotesPrefill${idx}`]: comp.notes,
              [`offerCompSelectedPrefill${idx}`]: comp.selected,
              [`offerCompDistancePrefill${idx}`]: comp.distance,
              [`estimatedConditionScorePrefill${idx}`]:
                comp.conditionScore ||
                oldRemodelCostMap[comp.estimatedRemodelCost || 15000],
              [`flatAvmAdjustmentPrefill${idx}`]: comp.flatAvmAdjustment,
            })
          ) || [])
        )
        const addressUuidMap = new Map(
          sortedComps.map((comp: Core_OfferValuationCompInput) => [
            comp.address!,
            comp.addressUuid!,
          ])
        )
        setOfferCompAddressUuidMap(addressUuidMap)
        setNumberPrefillOfferComps(sortedComps.length)
        setPrefilledOfferComps(sortedComps)
        setInjectedFormValues(offerPriceCompsPrefill)
      }
    },
    // JSON.stringify prevents infinite loop, but it still warns so ignore it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [combineAndSortComps, JSON.stringify(prefilledOfferComps)]
  )

  // determines message that shows which comps have been updated AFTER
  // the last time this valuation was computed
  const parseRentCompsForOutOfDateMessage = useCallback(
    (
      comps: Core_MarketRentCompInput[] | null | undefined,
      valuation: Core_ValuationV1 | null | undefined
    ) => {
      if (comps && comps.length !== 0 && valuation) {
        // need a small buffer time because comps in backend are created after valuation
        const valuationTimeToCompare = new Date(valuation.updatedAt)
        valuationTimeToCompare.setSeconds(
          valuationTimeToCompare.getSeconds() + 2
        )
        const outOfDateCompsMessage = comps.map(
          (comp: Core_MarketRentCompInput) => {
            const compDate = new Date(comp.updatedAt)
            if (compDate > valuationTimeToCompare) {
              return (
                <>
                  {comp.address}
                  <br />
                </>
              )
            }
            return null
          }
        )
        const filteredMessages = outOfDateCompsMessage.filter(
          (message) => message
        )
        if (filteredMessages.length > 0) {
          setRentCompsOutOfDateMessage(filteredMessages)
        } else {
          setRentCompsOutOfDateMessage('')
        }
      }
    },
    []
  )

  // determines message that shows which comps have been updated AFTER
  // the last time this valuation was computed
  const parseOfferCompsForOutOfDateMessage = useCallback(
    (
      comps: Core_OfferValuationCompInput[] | null | undefined,
      valuation: Core_ValuationV1 | null | undefined
    ) => {
      if (comps && comps.length !== 0 && valuation) {
        // need some buffer time because comps in backend are created after valuation
        const valuationTimeToCompare = new Date(valuation.updatedAt)
        valuationTimeToCompare.setSeconds(
          valuationTimeToCompare.getSeconds() + 2
        )
        const outOfDateCompsMessage = comps.map(
          (comp: Core_OfferValuationCompInput) => {
            const compDate = new Date(comp.updatedAt)
            if (compDate > valuationTimeToCompare) {
              return (
                <>
                  {comp.address}
                  <br />
                </>
              )
            }
            return null
          }
        )
        const filteredMessages = outOfDateCompsMessage.filter(
          (message) => message
        )
        if (filteredMessages.length > 0) {
          setOfferCompsOutOfDateMessage(filteredMessages)
        } else {
          setOfferCompsOutOfDateMessage('')
        }
      }
    },
    []
  )

  const { data: valuation } = useQuery(
    AdminAddressValuationsGetSingleFamilyAddressValuationDocument,
    {
      // for whatever reason this query refetches when switching tabs without this, but multifamily doesn't
      // adding no-cache here to keep consistent.
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          addressId,
        },
      },
      onError: () => {
        let prefilledValuationInputs = {
          ...valuationInputs,
        } as Core_ComputeSalesforceAddressValuationRequestInput
        prefilledValuationInputs = getPrefilledValuationFieldsFromSalesforce(
          addressData?.salesforceAddress?.address,
          prefilledValuationInputs
        )
        setCurrentlyOccupied(
          (prefilledValuationInputs?.marketRent?.currentRent || 0) > 0
        )
        setValuationInputs({
          ...prefilledValuationInputs,
        })
        setShouldPrefillCompsFromDb(false)
        setLoading(false)
      },
      onCompleted: async () => {
        const previousValuationDetails: Core_ValuationV1 = valuation
          ?.getSingleFamilyAddressValuation?.valuation as Core_ValuationV1

        setPreviousValuation(previousValuationDetails)

        let prefilledValuationInputs = {
          ...valuationInputs,
        } as Core_ComputeSalesforceAddressValuationRequestInput
        prefilledValuationInputs = getPrefilledValuationFieldsFromSalesforce(
          addressData?.salesforceAddress?.address,
          prefilledValuationInputs
        )
        if (previousValuationDetails) {
          prefilledValuationInputs = {
            ...prefilledValuationInputs,
            marketRent: previousValuationDetails.inputs?.marketRent,
            offerPrice: previousValuationDetails.inputs?.offerPrice,
            otherCosts: previousValuationDetails.inputs?.otherCosts,
            remodelCost: previousValuationDetails.inputs?.remodelCost,
            property: {
              ...prefilledValuationInputs.property,
              ...previousValuationDetails.property,
            },
            isSectionEight: previousValuationDetails.inputs?.isSectionEight,
          }

          if (previousValuationDetails.finalOfferPrice === 0) {
            prefilledValuationInputs.valuationUuid =
              previousValuationDetails.uuid
            prefilledValuationInputs.valuationType = GetUiValuationType(
              previousValuationDetails.type
            )
            setComputedValuationResult(previousValuationDetails)
          }
          setUseCma(!!prefilledValuationInputs?.offerPrice?.useCma)

          setValuationType(GetUiValuationType(previousValuationDetails.type))
          setOfferPriceValue(previousValuationDetails.finalOfferPrice || 0)
        }
        setCurrentlyOccupied(
          (prefilledValuationInputs?.marketRent?.currentRent || 0) > 0
        )
        setValuationInputs({
          ...prefilledValuationInputs,
        })

        // getting comps from the comps table for prefill
        const { data: marketRentComps } =
          await fetchMarketRentCompsForValuation({
            input: {
              valuationUuid: previousValuationDetails.uuid,
            },
          })
        const { data: offerPriceComps } =
          await fetchOfferPriceCompsForValuation({
            input: {
              valuationUuid: previousValuationDetails.uuid,
            },
          })

        // if we have comps in our comps table for this valuation, that means that we submitted compute valuation
        // with the new comps already, and therefore should not need to prefill
        if (
          !offerPriceComps?.getOfferPriceCompsForAddressValuation?.comps?.length
        ) {
          parseExistingJsonOfferComps(
            prefilledValuationInputs?.offerPrice?.comps
          )
        }
        if (
          !marketRentComps?.getMarketRentCompsForAddressValuation?.comps?.length
        ) {
          parseExistingJsonRentalComps(
            prefilledValuationInputs?.marketRent?.comps
          )
        }
        // setLoading(false) here because
        // for the rentalcompsprefill/offercompsprefill we inject the values
        // and the form must be rendered before that occurs which is triggered by setLoading
        setLoading(false)
        if (shouldPrefillCompsFromDb) {
          parseRentalCompsForPrefill(
            marketRentComps?.getMarketRentCompsForAddressValuation?.comps
          )

          parseOfferCompsForPrefill(
            offerPriceComps?.getOfferPriceCompsForAddressValuation?.comps
          )
        }
        setShouldPrefillCompsFromDb(false)
      },
      skip: !addressData,
    }
  )

  const [computeValuation, { loading: computeValuationLoading }] = useMutation(
    AdminAddressValuationsComputeSalesforceAddressValuationDocument,
    {
      fetchPolicy: 'no-cache',
      onError: () => {
        notify(
          'An error occurred while computing the valuation. Please try again.',
          'error'
        )
      },
      onCompleted: () => {
        notify('Valuation successfully computed', 'success')
      },
      refetchQueries: [
        AdminAddressValuationsGetSingleFamilyAddressValuationDocument,
        AdminAddressValuationsGetAddressValuationHistoryDocument,
      ],
    }
  )

  const getNormalOfferCompsFromPrefill = useCallback(
    (comps: OfferCompPrefillFormInput[]): OfferCompFormInput[] =>
      comps.map(
        (comp: OfferCompPrefillFormInput) =>
          ({
            offerCompSource: comp.offerCompSourcePrefill,
            similarityScore: comp.similarityScorePrefill,
            offerCompUrl: comp.offerCompUrlPrefill,
            avmPrice: comp.avmPricePrefill,
            squareFootage: comp.squareFootagePrefill,
            offerCompAddress: comp.offerCompAddressPrefill,
            beds: comp.bedsPrefill,
            baths: comp.bathsPrefill,
            halfBaths: comp.halfBathsPrefill,
            yearBuilt: comp.yearBuiltPrefill,
            offerCompSoldDate: comp.offerCompSoldDatePrefill,
            offerCompNotes: comp.offerCompNotesPrefill,
            offerCompSelected: comp.offerCompSelectedPrefill,
            offerCompDistance: comp.offerCompDistancePrefill,
            conditionScore: comp.estimatedConditionScorePrefill,
            flatAvmAdjustment: comp.flatAvmAdjustmentPrefill,
          } as OfferCompFormInput)
      ),
    []
  )

  const getNormalRentCompsFromPrefill = useCallback(
    (comps: RentalCompPrefillFormInput[]): RentalCompFormInput[] =>
      comps.map(
        (comp: RentalCompPrefillFormInput) =>
          ({
            rentCompSource: comp.rentCompSourcePrefill,
            rentCompMarketRent: comp.rentCompMarketRentPrefill,
            rentCompRentalDate: comp.rentCompRentalDatePrefill,
            rentCompSquareFootage: comp.rentCompSquareFootagePrefill,
            rentCompUrl: comp.rentCompUrlPrefill,
            rentCompAddress: comp.rentCompAddressPrefill,
            rentCompNotes: comp.rentCompNotesPrefill,
            rentCompSelected: comp.rentCompSelectedPrefill,
            rentCompDistance: comp.rentCompDistancePrefill,
          } as RentalCompFormInput)
      ),
    []
  )

  const getValuationTypeDefaultValue = useCallback(
    (inputValuationType: Core_ValuationType) => {
      if (inputValuationType === Core_ValuationType.ValuationTypePreliminary) {
        return Core_ValuationType.ValuationTypeInitial
      }
      return inputValuationType
    },
    []
  )

  const getDefaultOfferPriceComps = useCallback(() => {
    if (Object.keys(legacyOfferCompsPrefill).length === 0) {
      return Array(3).fill({
        offerCompSource: Core_CompSource.CompSourceHousecanary,
        conditionScore: 3,
      })
    }
    return undefined
  }, [legacyOfferCompsPrefill])

  const getDefaultMarketRentComps = useCallback(() => {
    if (Object.keys(legacyRentalCompsPrefill).length === 0) {
      return Array(3).fill({
        rentCompSource: Core_CompSource.CompSourceHousecanary,
      })
    }
    return undefined
  }, [legacyRentalCompsPrefill])

  useEffect(() => {
    if (valuationHistoryLoading) return
    const singleFamilyValuations =
      valuationHistory?.getAddressValuationHistory?.singleFamilyValuations
    if (singleFamilyValuations) {
      const existsInitialSubmittedValuation = singleFamilyValuations.some(
        (singleFamilyValuation) =>
          singleFamilyValuation?.finalOfferPrice &&
          singleFamilyValuation.finalOfferPrice > 0
      )
      if (existsInitialSubmittedValuation) {
        setHasSubmittedInitialValuation(true)
      }
    }
  }, [valuationHistory, valuationHistoryLoading])

  useEffect(() => {
    parseOfferCompsForOutOfDateMessage(prefilledOfferComps, previousValuation)
  }, [
    prefilledOfferComps,
    previousValuation,
    parseOfferCompsForOutOfDateMessage,
  ])

  useEffect(() => {
    parseRentCompsForOutOfDateMessage(prefilledRentalComps, previousValuation)
  }, [
    prefilledRentalComps,
    previousValuation,
    parseRentCompsForOutOfDateMessage,
  ])

  const computeValuationFormFormProps = useMemo(
    () => ({
      defaultValues: {
        valuationType: getValuationTypeDefaultValue(
          valuationInputs?.valuationType ||
            Core_ValuationType.ValuationTypeInitial
        ),
        ...legacyRentalCompsPrefill,
        ...legacyOfferCompsPrefill,
        // if we don't have prefill on the legacy, then we want to set some default values on the dropdowns
        offerPriceComps: getDefaultOfferPriceComps(),
        marketRentComps: getDefaultMarketRentComps(),
        hoaExists: valuationInputs?.otherCosts?.hoaExists ? 'yes' : 'no',
        currentlyOccupied: valuationInputs?.marketRent?.currentRent
          ? 'yes'
          : 'no',
        isSectionEight: valuationInputs?.isSectionEight ? 'yes' : 'no',
      },
    }),
    [
      valuationInputs,
      legacyRentalCompsPrefill,
      legacyOfferCompsPrefill,
      getDefaultOfferPriceComps,
      getDefaultMarketRentComps,
      getValuationTypeDefaultValue,
    ]
  )

  const operatorUuid = operatorData?.operator?.operator?.uuid || ''

  let formattedAddress = ''
  if (addressData) {
    formattedAddress =
      addressData?.salesforceAddress?.address?.formattedAddress || ''
  }
  const getSupervisorReviewRequiredMessage = (): string => {
    if (computedValuationResult?.outputs?.finalOfferPrice! > 600000) {
      return 'Final Offer price is greater than 600k.'
    }
    if (
      analystProjectedRentPerSqFtVsRentalCompAverageRentPerSqFt < 0.9 ||
      analystProjectedRentPerSqFtVsRentalCompAverageRentPerSqFt > 1.1
    ) {
      return 'Analyst Projected Rent per SqFt is not within 10% of the average of the selected rental comps.'
    }
    if (
      analystProjectedRentVsHousecanaryProjectedRent < 0.9 ||
      analystProjectedRentVsHousecanaryProjectedRent > 1.1
    ) {
      return 'Analyst Projected Rent is not within 10% of the HouseCanary Projected Rent.'
    }
    if (
      costBasisPerSqFt / compBasisPerSqFt < 0.9 ||
      costBasisPerSqFt / compBasisPerSqFt > 1.1
    ) {
      return 'Cost Basis per SqFt is not within 10% of the average of the selected offer price comps.'
    }
    if (valuationInputs?.property?.propertyType === 'condo') {
      return 'Property type is a condo.'
    }
    return ''
  }

  const canSubmitValuation = () => {
    const supervisorRequiredReason = getSupervisorReviewRequiredMessage()
    if (supervisorRequiredReason) {
      if (!supervisorUuids.includes(operatorUuid as string)) {
        notify(
          `Supervisor must review and submit this valuation. ${supervisorRequiredReason}`
        )
        return false
      }
    }
    if (valuationType === Core_ValuationType.ValuationTypeFinal) {
      if (!finalSubmitUuids.includes(operatorUuid as string)) {
        notify('Only supervisor can submit final valuation')
        return false
      }
    }
    return true
  }

  const canRejectValuation = () => {
    const supervisorRequiredReason = getSupervisorReviewRequiredMessage()
    if (supervisorRequiredReason) {
      if (!supervisorUuids.includes(operatorUuid as string)) {
        notify(
          `Supervisor must review and reject this valuation. ${supervisorRequiredReason}`
        )
        return false
      }
    }
    return true
  }

  const validateRentalInputs = (currentFormState: any) => {
    let analystProjected = 0
    let housecanaryProjected = 0
    if (
      !currentFormState.analystProjectedRent ||
      !currentFormState.housecanaryProjectedRent
    ) {
      analystProjected = valuationInputs?.marketRent?.analystProjectedRent || 0
      housecanaryProjected =
        valuationInputs?.marketRent?.housecanaryProjectedRent || 0
    } else {
      analystProjected = currentFormState.analystProjectedRent

      housecanaryProjected = currentFormState.housecanaryProjectedRent
    }

    let cumCompRentPerSqFt = 0
    const marketRentComps = currentFormState.marketRentComps || []
    if (currentFormState.marketRentCompsPrefill) {
      const prefilledMarketRentComps = getNormalRentCompsFromPrefill(
        currentFormState.marketRentCompsPrefill
      )
      marketRentComps.push(...prefilledMarketRentComps)
    }
    marketRentComps.forEach((comp: RentalCompFormInput) => {
      if (comp.rentCompSelected) {
        cumCompRentPerSqFt +=
          (comp.rentCompMarketRent || 0) / (comp.rentCompSquareFootage || 2000)
      }
    })
    const numberSelectedRentComps = marketRentComps.filter(
      (comp: RentalCompFormInput) => comp.rentCompSelected
    ).length

    const sqft =
      currentFormState.sqft || valuationInputs?.property?.sqft || 2000

    const analystProjectedRentPerSqFt = analystProjected / sqft
    const projectedVsComps =
      analystProjectedRentPerSqFt /
      (cumCompRentPerSqFt / numberSelectedRentComps)
    setAnalystProjectedRentPerSqFtVsRentalCompAverage(projectedVsComps)

    setAnalystProjectedRentVsHousecanaryProjectedRent(
      analystProjected / housecanaryProjected
    )
  }

  const validateOfferPriceInputs = (currentFormState: any) => {
    if (currentFormState.housecanaryAdjustedAvmPrice) {
      setOfferPriceValue(currentFormState.housecanaryAdjustedAvmPrice)
    }
  }

  const validateBasisPerSqFt = (currentFormState: any) => {
    let cumCompBasis = 0
    let cumCompSqFt = 0

    const offerPriceComps = currentFormState.offerPriceComps || []
    if (currentFormState.offerPriceCompsPrefill) {
      const prefilledOfferPriceComps = getNormalOfferCompsFromPrefill(
        currentFormState.offerPriceCompsPrefill
      )
      offerPriceComps.push(...prefilledOfferPriceComps)
    }
    offerPriceComps.forEach((comp: OfferCompFormInput) => {
      if (comp.offerCompSelected) {
        cumCompBasis += comp.avmPrice || 0
        cumCompSqFt += comp.squareFootage || 2000
        cumCompBasis += comp.conditionScore
          ? remodelCostMap[comp.conditionScore] * (comp.squareFootage || 0)
          : 0
      }
    })
    setCompBasisPerSqFt(cumCompBasis / cumCompSqFt)

    const sqft = currentFormState.sqft || valuationInputs?.property?.sqft

    setCostBasisPerSqFt(currentFormState.housecanaryAdjustedAvmPrice / sqft)
  }

  const validateFormData = (currentFormState: any) => {
    validateRentalInputs(currentFormState)
    validateOfferPriceInputs(currentFormState)
    validateBasisPerSqFt(currentFormState)
    if (currentFormState.valuationType) {
      setValuationType(currentFormState.valuationType || '')
    }
    if (currentFormState.currentlyOccupied) {
      setCurrentlyOccupied(currentFormState.currentlyOccupied === 'yes')
    }
    setUseCma(currentFormState.useCma)
  }

  const computeValuationFormInputConfigs: AnyInputConfig[] = [
    {
      name: 'propertyDetails',
      type: InputType.Section,
      gridItemProps: { xs: 12, pt: '16px' },
      props: {
        title: 'Property Details',
        collapsible: true,
        collapsibleHorizontal: true,
        inputConfigs: [
          {
            name: 'propertyDetailsFirstColumn',
            type: InputType.Section,
            gridItemProps: { xs: 4 },
            props: {
              title: '',
              sectionGridProps: { spacing: 0 },
              inputConfigs: [
                {
                  name: 'address',
                  type: InputType.CustomComponent,
                  props: {
                    component: (
                      <Typography variant="p2">
                        Address: {formattedAddress}
                      </Typography>
                    ),
                  },
                },
                {
                  name: 'county',
                  type: InputType.CustomComponent,
                  props: {
                    component: (
                      <Typography variant="p2">
                        County:{' '}
                        {addressData?.salesforceAddress?.address?.addressCounty}
                      </Typography>
                    ),
                  },
                },
                {
                  name: 'hoaExists',
                  type: InputType.Dropdown,
                  props: {
                    InputLabelProps: { shrink: true },
                    sx: { width: '50%' },
                    label: 'Is there an HOA?',
                    size: 'mini',
                    options: [
                      {
                        label: 'No',
                        value: 'no',
                      },
                      {
                        label: 'Yes',
                        value: 'yes',
                      },
                    ],
                  },
                },
                {
                  name: 'yearlyHoaFee',
                  type: InputType.Text,
                  renderIf: (watchedFields: any) =>
                    watchedFields.hoaExists === 'yes',
                  defaultValue: valuationInputs?.otherCosts?.hoa || 0,
                  props: {
                    size: 'mini',

                    label: 'HOA Fee (yearly)',
                    format: 'dollars',
                    placeholder: '$',
                  },
                },
                {
                  name: 'nearbyHoaAddress',
                  type: InputType.Text,
                  renderIf: (watchedFields: any) =>
                    watchedFields.hoaExists === 'yes',
                  defaultValue: `${
                    valuationInputs?.otherCosts?.addressNearbyHomeHoa || ''
                  }`,
                  props: {
                    size: 'mini',
                    label: 'Address of Nearby Home in same HOA',
                    format: 'text',
                  },
                },
                {
                  name: 'nearbyHoaUrl',
                  type: InputType.Text,
                  renderIf: (watchedFields: any) =>
                    watchedFields.hoaExists === 'yes',
                  defaultValue: `${
                    valuationInputs?.otherCosts?.urlNearbyHomeHoa || ''
                  }`,
                  props: {
                    size: 'mini',
                    label: 'URL of Nearby Home in same HOA',
                    format: 'text',
                  },
                },
                {
                  name: 'computationDate',
                  type: InputType.DatePicker,
                  defaultValue: '',
                  props: {
                    size: 'mini',
                    label: 'Computation Date',
                    sx: { width: '50%' },
                  },
                },
                {
                  name: 'isSectionEight',
                  type: InputType.Dropdown,
                  defaultValue: `${
                    valuationInputs?.isSectionEight === true ? 'yes' : 'no'
                  }`,
                  props: {
                    InputLabelProps: { shrink: true },
                    sx: { width: '50%' },
                    label: 'Is Section Eight?',
                    size: 'mini',
                    options: [
                      {
                        label: 'No',
                        value: 'no',
                      },
                      {
                        label: 'Yes',
                        value: 'yes',
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            name: 'propertyDetailsSecondColumn',
            type: InputType.Section,
            gridItemProps: { xs: 6 },
            props: {
              title: '',
              sectionGridProps: { spacing: 0 },
              inputConfigs: [
                {
                  name: 'propertyType',
                  type: InputType.CustomComponent,
                  props: {
                    component: (
                      <Typography variant="p2">
                        Property Type: {valuationInputs?.property?.propertyType}
                      </Typography>
                    ),
                  },
                },
                {
                  name: 'beds',
                  type: InputType.CustomComponent,
                  props: {
                    component: (
                      <Typography variant="p2">
                        Beds: {valuationInputs?.property?.beds}
                      </Typography>
                    ),
                  },
                },
                {
                  name: 'baths',
                  type: InputType.CustomComponent,
                  props: {
                    component: (
                      <Typography variant="p2">
                        Baths: {valuationInputs?.property?.baths}
                      </Typography>
                    ),
                  },
                },
                {
                  name: 'halfBaths',
                  type: InputType.CustomComponent,
                  props: {
                    component: (
                      <Typography variant="p2">
                        Half Baths: {valuationInputs?.property?.halfBaths}
                      </Typography>
                    ),
                  },
                },
                {
                  name: 'additionalInfo',
                  type: InputType.CustomComponent,
                  props: {
                    component: (
                      <Typography variant="p2">
                        Additional Info:{' '}
                        {
                          addressData?.salesforceAddress?.address
                            ?.additionalInfo
                        }
                      </Typography>
                    ),
                  },
                },
                {
                  name: 'selfReportedValue',
                  type: InputType.CustomComponent,
                  props: {
                    component: (
                      <Typography variant="p2">
                        Self Reported Value:{' '}
                        {formatIntegerDollars(
                          addressData?.salesforceAddress?.address
                            ?.selfReportedValue || 0
                        )}
                      </Typography>
                    ),
                  },
                },
                {
                  name: 'yearBuilt',
                  type: InputType.Text,
                  defaultValue: valuationInputs?.property?.yearBuilt,
                  props: {
                    sx: { width: '30%' },
                    size: 'mini',
                    label: 'Year Built',
                    format: 'year',
                  },
                },
                {
                  name: 'sqft',
                  type: InputType.Text,
                  defaultValue: valuationInputs?.property?.sqft,
                  props: {
                    sx: { width: '30%' },
                    size: 'mini',
                    label: 'Sqft',
                    format: 'number',
                  },
                },
                {
                  name: 'taxes',
                  type: InputType.Text,
                  required: true,
                  defaultValue: valuationInputs?.otherCosts?.propertyTaxes,
                  props: {
                    sx: { width: '30%' },
                    size: 'mini',
                    label: 'Property Taxes',
                    format: 'dollars',
                    placeholder: '$',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      name: 'propertyDetailsDivider',
      type: InputType.CustomComponent,
      gridItemProps: { xs: 12 },
      props: {
        component: <Divider sx={{ width: '100%', margin: '8px 0px' }} />,
      },
    },
    {
      name: 'valuationDetails',
      type: InputType.Section,
      gridItemProps: { xs: 12, pt: '16px' },
      props: {
        title: 'Valuation Details',
        collapsible: true,
        inputConfigs: [
          {
            name: 'valuationType',
            type: InputType.Dropdown,
            required: true,
            renderIf: () => hasSubmittedInitialValuation,
            gridItemProps: { xs: 3 },
            props: {
              size: 'mini',
              sx: {
                maxWidth: '150px',
              },
              label: 'Valuation Type',
              options: [
                {
                  label: 'Initial',
                  value: Core_ValuationType.ValuationTypeInitial,
                },
                {
                  label: 'Final',
                  value: Core_ValuationType.ValuationTypeFinal,
                },
              ],
            },
          },
          {
            name: 'healthAndSafetyCapex',
            type: InputType.Text,
            gridItemProps: { xs: 4 },
            renderIf: (watchedFields: any) =>
              watchedFields.valuationType ===
              Core_ValuationType.ValuationTypeFinal,
            required: true,
            defaultValue:
              valuationInputs?.remodelCost?.healthAndSafetyCapex || 0,
            props: {
              size: 'mini',
              label: 'Health And Safety Capex',
              format: 'dollars',
              placeholder: '$',
            },
          },
          {
            name: 'immediateAddressableCapex',
            type: InputType.Text,
            gridItemProps: { xs: 4 },
            renderIf: (watchedFields: any) =>
              watchedFields.valuationType ===
              Core_ValuationType.ValuationTypeFinal,
            required: true,
            defaultValue:
              (valuationInputs?.remodelCost?.immediateAddressableCapex || 0) -
                (valuationInputs?.remodelCost?.healthAndSafetyCapex || 0) ||
              valuationInputs?.remodelCost?.sowCost ||
              0,
            props: {
              size: 'mini',
              label: 'Immediate Capex',
              format: 'dollars',
              placeholder: '$',
            },
          },
          {
            name: 'totalAddressableCapex',
            type: InputType.Text,
            gridItemProps: { xs: 4 },
            renderIf: (watchedFields: any) =>
              watchedFields.valuationType ===
              Core_ValuationType.ValuationTypeFinal,
            required: true,
            defaultValue:
              (valuationInputs?.remodelCost?.totalAddressableCapex || 0) -
                (valuationInputs?.remodelCost?.immediateAddressableCapex ||
                  0) || 0,
            props: {
              size: 'mini',
              label: 'Non Immediate Capex',
              format: 'dollars',
              placeholder: '$',
            },
          },
          {
            name: 'remodelCostScore',
            type: InputType.Dropdown,
            renderIf: (watchedFields: any) =>
              watchedFields.valuationType !==
              Core_ValuationType.ValuationTypeFinal,
            required: true,
            gridItemProps: { xs: 6 },
            defaultValue: valuationInputs?.remodelCost?.score || 3,
            props: {
              size: 'mini',
              label:
                'Remodel Cost Score (Verify with Zillow/HouseCanary photos)',
              options: [
                {
                  label: '(5) Pristine',
                  value: 5,
                },
                {
                  label: '(4) Great',
                  value: 4,
                },
                {
                  label: '(3) Average',
                  value: 3,
                },
                {
                  label: '(2) Below Average',
                  value: 2,
                },
                {
                  label: '(1) Requires Complete Renovation',
                  value: 1,
                },
              ],
            },
          },
          {
            name: 'remodelNotes',
            type: InputType.Text,
            gridItemProps: { xs: 6 },
            defaultValue: `${valuationInputs?.remodelCost?.notes || ''}`,
            props: {
              size: 'mini',
              multiline: true,
              maxRows: 3,
              label: 'Remodel Notes',
            },
          },
          {
            name: 'acquisitionCosts',
            type: InputType.Text,
            gridItemProps: { xs: 3 },
            defaultValue: valuationInputs?.otherCosts?.acquisition,
            props: {
              size: 'mini',
              label: 'Acquisition Costs',
              format: 'dollars',
              placeholder: '$',
            },
          },
          {
            name: 'miscellaneousCosts',
            type: InputType.Text,
            gridItemProps: { xs: 3 },
            defaultValue: valuationInputs?.otherCosts?.miscellaneous,
            props: {
              size: 'mini',
              label: 'Misc. Costs',
              format: 'dollars',
              placeholder: '$',
            },
          },
          {
            name: 'otherCostNotes',
            type: InputType.Text,
            gridItemProps: { xs: 6 },
            defaultValue: `${valuationInputs?.otherCosts?.notes || ''}`,
            props: {
              size: 'mini',
              multiline: true,
              maxRows: 3,
              label: 'Other Cost Notes',
            },
          },
          {
            name: 'additionalMonthlyCost',
            type: InputType.Text,
            gridItemProps: { xs: 3 },
            defaultValue: valuationInputs?.otherCosts?.additionalMonthlyCost,
            props: {
              size: 'mini',
              label: 'Additional Monthly Cost',
              format: 'dollars',
              placeholder: '$',
            },
          },
          {
            name: 'additionalMonthlyCostNotes',
            type: InputType.Text,
            gridItemProps: { xs: 6 },
            defaultValue: `${
              valuationInputs?.otherCosts?.additionalMonthlyCostNotes || ''
            }`,
            props: {
              size: 'mini',
              label: 'Additional Monthly Cost Notes',
              multiline: true,
              maxRows: 3,
              format: 'text',
            },
          },
        ],
      },
    },
    {
      name: 'otherInputsDivider',
      type: InputType.CustomComponent,
      gridItemProps: { xs: 12 },
      props: {
        component: <Divider sx={{ width: '100%', margin: '8px 0px' }} />,
      },
    },
    {
      name: 'offerPriceInputs',
      type: InputType.Section,
      gridItemProps: { xs: 12, pt: '16px' },
      props: {
        title: 'Offer Price Inputs',
        collapsible: true,
        inputConfigs: [
          {
            name: 'housecanaryAdjustedAvmPrice',
            type: InputType.Text,
            gridItemProps: { xs: 5 },
            required: !useCma,
            defaultValue: valuationInputs?.offerPrice
              ?.housecanaryAdjustedAvmPrice
              ? Math.trunc(
                  valuationInputs.offerPrice.housecanaryAdjustedAvmPrice
                )
              : undefined,
            props: {
              size: 'mini',
              label: 'Housecanary Adjusted AVM Price (Offer Price)',
              format: 'dollars',
              placeholder: '$',
              disabled: useCma,
            },
          },
          {
            name: 'useCma',
            type: InputType.Checkbox,
            gridItemProps: { xs: 4 },
            defaultValue: !!valuationInputs?.offerPrice?.useCma,
            props: {
              label:
                'Use CMA for AVM calculation (will override any existing Housecanary AVM value)',
            },
          },
          {
            name: 'propertyFlatAvmAdjustment',
            type: InputType.Text,
            gridItemProps: { xs: 3 },
            defaultValue:
              valuationInputs?.offerPrice?.propertyFlatAvmAdjustment,
            props: {
              size: 'mini',
              label: 'AVM Adjustment (property)',
              format: 'dollars',
              placeholder: '$',
            },
          },
          {
            name: 'offerPriceNotes',
            type: InputType.Text,
            gridItemProps: { xs: 6 },
            defaultValue: valuationInputs?.offerPrice?.notes,
            props: {
              size: 'mini',
              multiline: true,
              maxRows: 3,
              label: 'Offer Price Notes',
            },
          },
          {
            name: 'offerPriceChecks',
            type: InputType.CustomComponent,
            gridItemProps: { xs: 6 },
            renderIf: () => offerPriceValue >= 600000,
            props: {
              component: (
                <Typography variant="p2" color="red">
                  Asset valued at over $600K. Escalate to supervisor for review.
                </Typography>
              ),
            },
          },
          {
            name: 'fetchOfferCompsButton',
            type: InputType.CustomComponent,
            gridItemProps: { xs: 12 },
            props: {
              component: (
                <Button
                  size="mini"
                  onClick={() => {
                    setFetchOfferCompsModalOpen(true)
                  }}
                  sx={{ width: '50%' }}
                >
                  Fetch Offer Comps
                </Button>
              ),
            },
          },
          {
            name: 'offerCompsOutOfDateMessage',
            type: InputType.CustomComponent,
            gridItemProps: { xs: 12 },
            renderIf: () => !!offerCompsOutOfDateMessage,
            props: {
              component: (
                <>
                  <Typography variant="p2" color="red">
                    The following offer comps have been updated since the
                    valuation was last computed. Please check to make sure the
                    values are correct.
                    <br />
                    {offerCompsOutOfDateMessage}
                  </Typography>
                </>
              ),
            },
          },
          {
            name: 'offerPriceCompsPrefill',
            type: InputType.Multiform,
            gridItemProps: { sx: { minWidth: '160px' } },
            renderIf: () => numberPrefillOfferComps > 0,
            defaultValue: numberPrefillOfferComps,
            props: {
              title: 'Offer Price Comps Prefill',
              minForms: numberPrefillOfferComps,
              maxForms: numberPrefillOfferComps,
              horizontal: true,
              itemContainerProps: { spacing: 1 },
              inputConfigs: [
                {
                  name: 'offerCompSelectedPrefill',
                  type: InputType.Checkbox,
                  props: {
                    label: 'Selected',
                  },
                },
                {
                  name: 'offerCompSourcePrefill',
                  type: InputType.Dropdown,
                  props: {
                    size: 'mini',
                    label: 'Source',
                    InputLabelProps: { shrink: true },
                    options: [
                      {
                        label: 'Housecanary',
                        value: Core_CompSource.CompSourceHousecanary,
                      },
                      {
                        label: 'Zillow',
                        value: Core_CompSource.CompSourceZillow,
                      },
                    ],
                  },
                },
                {
                  name: 'offerCompAddressPrefill',
                  type: InputType.Text,
                  props: {
                    disabled: true,
                    size: 'mini',
                    label: 'Address',
                    format: 'text',
                  },
                },
                {
                  name: 'bedsPrefill',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Beds',
                    format: 'number',
                  },
                },
                {
                  name: 'bathsPrefill',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Baths',
                    format: 'number',
                  },
                },
                {
                  name: 'halfBathsPrefill',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Half Baths',
                    format: 'number',
                  },
                },
                {
                  name: 'squareFootagePrefill',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Square Footage',
                    format: 'number',
                  },
                },
                {
                  name: 'similarityScorePrefill',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Similarity Score',
                    format: 'number',
                  },
                },
                {
                  name: 'offerCompSoldDatePrefill',
                  type: InputType.DatePicker,
                  props: {
                    size: 'mini',
                    label: 'Sold Date',
                  },
                },
                {
                  name: 'avmPricePrefill',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Sold Price',
                    format: 'dollars',
                    placeholder: '$',
                  },
                },
                {
                  name: 'yearBuiltPrefill',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Year Built',
                    format: 'year',
                  },
                },
                {
                  name: 'estimatedConditionScorePrefill',
                  type: InputType.Dropdown,
                  props: {
                    size: 'mini',
                    InputLabelProps: { shrink: true },
                    label: 'Estimated Condition',
                    options: [
                      {
                        label: '(5) Pristine // Well-Maintained',
                        value: 5,
                      },
                      {
                        label: '(4) Great // Well-Maintained',
                        value: 4,
                      },
                      {
                        label: '(3) Average // Worn but adequate',
                        value: 3,
                      },
                      {
                        label: '(2) Below Average // Needs Work',
                        value: 2,
                      },
                      {
                        label: '(1) Requires Complete Renovation // Needs Work',
                        value: 1,
                      },
                    ],
                  },
                },
                {
                  name: 'offerCompUrlPrefill',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'URL',
                    format: 'text',
                  },
                },
                {
                  name: 'offerCompDistancePrefill',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Distance (mi)',
                    format: 'number',
                  },
                },
                {
                  name: 'flatAvmAdjustmentPrefill',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Flat AVM Adjustment',
                    format: 'dollars',
                    placeholder: '$',
                  },
                },
                {
                  name: 'offerCompNotesPrefill',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Notes',
                    multiline: true,
                    maxRows: 3,
                    format: 'text',
                  },
                },
              ],
            },
          },
          {
            name: 'offerPriceComps',
            type: InputType.Multiform,
            gridItemProps: { sx: { minWidth: '160px' } },
            defaultValue: 4,
            props: {
              title: 'Offer Price Comps',
              minForms: 4,
              maxForms: 4,
              horizontal: true,
              itemContainerProps: { spacing: 1 },
              inputConfigs: [
                {
                  name: 'offerCompSelected',
                  type: InputType.Checkbox,
                  props: {
                    label: 'Selected',
                  },
                },
                {
                  name: 'offerCompSource',
                  type: InputType.Dropdown,
                  props: {
                    size: 'mini',
                    label: 'Source',
                    InputLabelProps: { shrink: true },
                    options: [
                      {
                        label: 'Housecanary',
                        value: Core_CompSource.CompSourceHousecanary,
                      },
                      {
                        label: 'Zillow',
                        value: Core_CompSource.CompSourceZillow,
                      },
                    ],
                  },
                },
                {
                  name: 'offerCompAddress',
                  type: InputType.Address,
                  props: {
                    size: 'mini',
                    label: 'Address',
                  },
                },
                {
                  name: 'beds',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Beds',
                    format: 'number',
                  },
                },
                {
                  name: 'baths',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Baths',
                    format: 'number',
                  },
                },
                {
                  name: 'halfBaths',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Half Baths',
                    format: 'number',
                  },
                },
                {
                  name: 'squareFootage',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Square Footage',
                    format: 'number',
                  },
                },
                {
                  name: 'similarityScore',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Similarity Score',
                    format: 'number',
                  },
                },
                {
                  name: 'offerCompSoldDate',
                  type: InputType.DatePicker,
                  props: {
                    size: 'mini',
                    label: 'Sold Date',
                  },
                },
                {
                  name: 'avmPrice',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Sold Price',
                    format: 'dollars',
                    placeholder: '$',
                  },
                },
                {
                  name: 'yearBuilt',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Year Built',
                    format: 'year',
                  },
                },
                {
                  name: 'conditionScore',
                  type: InputType.Dropdown,
                  props: {
                    size: 'mini',
                    InputLabelProps: { shrink: true },
                    label: 'Estimated Condition',
                    options: [
                      {
                        label: '(5) Pristine // Well-Maintained',
                        value: 5,
                      },
                      {
                        label: '(4) Great // Well-Maintained',
                        value: 4,
                      },
                      {
                        label: '(3) Average // Worn but adequate',
                        value: 3,
                      },
                      {
                        label: '(2) Below Average // Needs Work',
                        value: 2,
                      },
                      {
                        label: '(1) Requires Complete Renovation // Needs Work',
                        value: 1,
                      },
                    ],
                  },
                },
                {
                  name: 'offerCompUrl',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'URL',
                    format: 'text',
                  },
                },
                {
                  name: 'offerCompDistance',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Distance (mi)',
                    format: 'number',
                  },
                },
                {
                  name: 'flatAvmAdjustment',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Flat AVM Adjustment',
                    format: 'dollars',
                    placeholder: '$',
                  },
                },
                {
                  name: 'offerCompNotes',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Notes',
                    multiline: true,
                    maxRows: 3,
                    format: 'text',
                  },
                },
              ],
            },
          },
          {
            name: 'costBasisPerSqFtChecks',
            type: InputType.CustomComponent,
            props: {
              component: !useCma && (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="p2">
                    {`Cost Basis / Sqft: ${costBasisPerSqFt.toFixed(2)}`}
                  </Typography>
                  <Typography variant="p2">
                    {`Comps Cost Basis / Sqft: ${compBasisPerSqFt.toFixed(2)}`}
                  </Typography>
                  <br />
                  <Typography
                    variant="p2"
                    color={
                      costBasisPerSqFt / compBasisPerSqFt > 0.9 &&
                      costBasisPerSqFt / compBasisPerSqFt < 1.1
                        ? 'green'
                        : 'red'
                    }
                  >
                    {`Cost Basis / Sqft vs Comp Cost Basis / Sqft: ${formatPercentage(
                      costBasisPerSqFt / compBasisPerSqFt,
                      2
                    )}`}
                    <br />
                    {`${
                      costBasisPerSqFt / compBasisPerSqFt < 0.9 ||
                      costBasisPerSqFt / compBasisPerSqFt > 1.1
                        ? 'Average cost basis / sq. ft. of comps are >10% different from ' +
                          'cost basis / sq. ft. of lead property. Verify lead property details ' +
                          'are correct on HouseCanary or provide justification in Offer ' +
                          'Price Notes and escalate for supervisor review.'
                        : ''
                    }`}
                  </Typography>
                </Box>
              ),
            },
          },
        ],
      },
    },
    {
      name: 'offerPriceInputsDivider',
      type: InputType.CustomComponent,
      gridItemProps: { xs: 12 },
      props: {
        component: <Divider sx={{ width: '100%', margin: '8px 0px' }} />,
      },
    },
    {
      name: 'marketRentInputs',
      type: InputType.Section,
      gridItemProps: { xs: 12, pt: '16px' },
      props: {
        title: 'Market Rent Inputs',
        collapsible: true,
        inputConfigs: [
          {
            name: 'currentlyOccupied',
            type: InputType.Dropdown,
            gridItemProps: { xs: 6 },
            defaultValue: valuationInputs?.marketRent?.currentRent
              ? 'yes'
              : 'no',
            props: {
              InputLabelProps: { shrink: true },
              size: 'mini',
              label:
                valuationType === Core_ValuationType.ValuationTypeFinal
                  ? 'Currently Occupied? (Verify current lease(s) are uploaded)'
                  : 'Currently Occupied?',
              options: [
                {
                  label: 'Yes',
                  value: 'yes',
                },
                {
                  label: 'No',
                  value: 'no',
                },
              ],
            },
          },
          {
            name: 'currentRent',
            type: InputType.Text,
            gridItemProps: { xs: 6 },
            defaultValue: valuationInputs?.marketRent?.currentRent,
            required: currentlyOccupied,
            props: {
              size: 'mini',
              disabled: !currentlyOccupied,
              label: 'Current Monthly Rent',
              format: 'dollars',
              placeholder: '$',
            },
          },
          {
            name: 'monthsRemainingOnLeaseMessage',
            type: InputType.CustomComponent,
            gridItemProps: { xs: 12 },
            renderIf: (watchedFields: any) =>
              watchedFields.currentlyOccupied === 'yes',
            props: {
              component: (
                <Typography variant="c1" sx={{ width: '50%' }}>
                  For month-to-month leases, enter 0 for months remaining on
                  lease
                </Typography>
              ),
            },
          },
          {
            name: 'monthsRemainingOnLease',
            type: InputType.Text,
            gridItemProps: { xs: 6 },
            defaultValue: currentlyOccupied
              ? valuationInputs?.marketRent?.monthsRemainingOnLease ||
                monthsRemainingOnLease
              : '',
            required: currentlyOccupied,
            props: {
              size: 'mini',
              disabled: !currentlyOccupied,
              label: 'Remaining Lease Length Months',
              format: 'text',
            },
          },
          {
            name: 'inPlacePropertyTaxes',
            type: InputType.Text,
            required: true,
            defaultValue: valuationInputs?.otherCosts?.inPlacePropertyTaxes,
            gridItemProps: { xs: 6 },
            renderIf: () =>
              valuationType === Core_ValuationType.ValuationTypeFinal,
            props: {
              sx: { width: '30%' },
              size: 'mini',
              label: 'In Place Property Taxes',
              format: 'dollars',
              placeholder: '$',
            },
          },
          {
            name: 'contributionMonth',
            type: InputType.DatePicker,
            gridItemProps: { xs: 6 },
            defaultValue: contributionMonth,
            props: {
              size: 'mini',
              label: `Expected contribution month ${contributionMonth.toLocaleString(
                'en-US',
                {
                  month: 'long',
                }
              )}`,
            },
          },
          {
            name: 'housecanaryProjectedRent',
            type: InputType.Text,
            required: true,
            gridItemProps: { xs: 3 },
            defaultValue: valuationInputs?.marketRent?.housecanaryProjectedRent,
            props: {
              size: 'mini',
              label: 'Housecanary Rent Projection',
              format: 'dollars',
              placeholder: '$',
            },
          },
          {
            name: 'analystProjectedRent',
            type: InputType.Text,
            required: true,
            defaultValue: valuationInputs?.marketRent?.analystProjectedRent,
            gridItemProps: { xs: 3 },
            props: {
              size: 'mini',
              label: 'Analyst Projected Rent',
              format: 'dollars',
              placeholder: '$',
            },
          },
          {
            name: 'rentNotes',
            type: InputType.Text,
            gridItemProps: { xs: 6 },
            defaultValue: valuationInputs?.marketRent?.notes || '',
            props: {
              size: 'mini',
              label: 'Rent Notes',
              multiline: true,
              maxRows: 3,
              format: 'text',
            },
          },
          {
            name: 'fetchRentCompsButton',
            type: InputType.CustomComponent,
            gridItemProps: { xs: 6 },
            props: {
              component: (
                <Button
                  size="mini"
                  onClick={() => {
                    setFetchRentalCompsModalOpen(true)
                  }}
                >
                  Fetch Rent Comps
                </Button>
              ),
            },
          },
          {
            name: 'rentCompsOutOfDateMessage',
            type: InputType.CustomComponent,
            gridItemProps: { xs: 12 },
            renderIf: () => !!rentCompsOutOfDateMessage,
            props: {
              component: (
                <>
                  <Typography variant="p2" color="red">
                    The following rent comps have been updated since the
                    valuation was last computed. Please check to make sure the
                    values are correct.
                    <br />
                    {rentCompsOutOfDateMessage}
                  </Typography>
                </>
              ),
            },
          },
          {
            name: 'marketRentCompsPrefill',
            type: InputType.Multiform,
            gridItemProps: { sx: { minWidth: '160px' } },
            renderIf: () => numberPrefillRentalComps > 0,
            defaultValue: numberPrefillRentalComps,
            props: {
              title: 'Prefilled Market Rent Comps',
              minForms: numberPrefillRentalComps,
              maxForms: numberPrefillRentalComps,
              horizontal: true,
              itemContainerProps: { spacing: 1 },
              inputConfigs: [
                {
                  name: 'rentCompSelectedPrefill',
                  type: InputType.Checkbox,
                  props: {
                    label: 'Selected',
                  },
                },
                {
                  name: 'rentCompSourcePrefill',
                  type: InputType.Dropdown,
                  props: {
                    label: 'Source',
                    InputLabelProps: { shrink: true },
                    size: 'mini',
                    options: [
                      {
                        label: 'Housecanary',
                        value: Core_CompSource.CompSourceHousecanary,
                      },
                      {
                        label: 'Zillow',
                        value: Core_CompSource.CompSourceZillow,
                      },
                    ],
                  },
                },
                {
                  name: 'rentCompAddressPrefill',
                  type: InputType.Text,
                  props: {
                    disabled: true,
                    size: 'mini',
                    label: 'Address',
                    format: 'text',
                  },
                },
                {
                  name: 'rentCompSquareFootagePrefill',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Square Footage',
                    format: 'number',
                  },
                },
                {
                  name: 'rentCompRentalDatePrefill',
                  type: InputType.DatePicker,
                  props: {
                    size: 'mini',
                    label: 'Rental Date',
                  },
                },
                {
                  name: 'rentCompMarketRentPrefill',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Market Rent',
                    format: 'dollars',
                    placeholder: '$',
                  },
                },
                {
                  name: 'rentCompUrlPrefill',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'URL',
                    format: 'text',
                  },
                },
                {
                  name: 'rentCompDistancePrefill',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Distance (mi)',
                    format: 'number',
                  },
                },
                {
                  name: 'rentCompNotesPrefill',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Notes',
                    multiline: true,
                    maxRows: 3,
                    format: 'text',
                  },
                },
              ],
            },
          },
          {
            name: 'marketRentComps',
            type: InputType.Multiform,
            gridItemProps: { sx: { minWidth: '160px' } },
            defaultValue: 3,
            props: {
              title: 'Market Rent Comps',
              minForms: 3,
              maxForms: 3,
              horizontal: true,
              itemContainerProps: { spacing: 1 },
              inputConfigs: [
                {
                  name: 'rentCompSelected',
                  type: InputType.Checkbox,
                  props: {
                    label: 'Selected',
                  },
                },
                {
                  name: 'rentCompSource',
                  type: InputType.Dropdown,
                  props: {
                    label: 'Source',
                    InputLabelProps: { shrink: true },
                    size: 'mini',
                    options: [
                      {
                        label: 'Housecanary',
                        value: Core_CompSource.CompSourceHousecanary,
                      },
                      {
                        label: 'Zillow',
                        value: Core_CompSource.CompSourceZillow,
                      },
                    ],
                  },
                },
                {
                  name: 'rentCompAddress',
                  type: InputType.Address,
                  props: {
                    size: 'mini',
                    label: 'Address',
                  },
                },
                {
                  name: 'rentCompSquareFootage',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Square Footage',
                    format: 'number',
                  },
                },
                {
                  name: 'rentCompRentalDate',
                  type: InputType.DatePicker,
                  props: {
                    size: 'mini',
                    label: 'Rental Date',
                  },
                },
                {
                  name: 'rentCompMarketRent',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Market Rent',
                    format: 'dollars',
                    placeholder: '$',
                  },
                },
                {
                  name: 'rentCompUrl',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'URL',
                    format: 'text',
                  },
                },
                {
                  name: 'rentCompDistance',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Distance (mi)',
                    format: 'number',
                  },
                },
                {
                  name: 'rentCompNotes',
                  type: InputType.Text,
                  props: {
                    size: 'mini',
                    label: 'Notes',
                    multiline: true,
                    maxRows: 3,
                    format: 'text',
                  },
                },
              ],
            },
          },
          {
            name: 'rentChecks',
            type: InputType.CustomComponent,
            props: {
              component: (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    variant="p2"
                    color={
                      analystProjectedRentPerSqFtVsRentalCompAverageRentPerSqFt <
                        1.1 &&
                      analystProjectedRentPerSqFtVsRentalCompAverageRentPerSqFt >
                        0.9
                        ? 'green'
                        : 'red'
                    }
                  >
                    {`Analyst Projected vs Rental Comp Average Rent / Sqft: ${formatPercentage(
                      analystProjectedRentPerSqFtVsRentalCompAverageRentPerSqFt,
                      2
                    )}`}
                    <br />
                    {`${
                      analystProjectedRentPerSqFtVsRentalCompAverageRentPerSqFt <
                        0.9 ||
                      analystProjectedRentPerSqFtVsRentalCompAverageRentPerSqFt >
                        1.1
                        ? 'Analyst determined rent / sq. ft. and average of 3 comps rent / sq. ft. differ by ' +
                          '>10%. Escalate to supervisor for review.'
                        : ''
                    }`}
                  </Typography>
                  <Typography
                    variant="p2"
                    color={
                      analystProjectedRentVsHousecanaryProjectedRent < 1.1 &&
                      analystProjectedRentVsHousecanaryProjectedRent > 0.9
                        ? 'green'
                        : 'red'
                    }
                  >
                    {`Analyst Projected vs Housecanary Projected: ${formatPercentage(
                      analystProjectedRentVsHousecanaryProjectedRent,
                      2
                    )}`}
                    <br />
                    {`${
                      analystProjectedRentVsHousecanaryProjectedRent < 0.9 ||
                      analystProjectedRentVsHousecanaryProjectedRent > 1.1
                        ? 'Analyst determined rent and HouseCanary Rent Projection differ ' +
                          'by >10%. Escalate to supervisor for review.'
                        : ''
                    }`}
                  </Typography>
                </Box>
              ),
            },
          },
        ],
      },
    },
    {
      name: 'finalComputeChecks',
      type: InputType.CustomComponent,
      renderIf: () => !!rentCompsErrorMessage || !!offerCompsErrorMessage,
      props: {
        component: (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ color: 'red' }}>
              {rentCompsErrorMessage}
            </Typography>
            <Typography sx={{ color: 'red' }}>
              {offerCompsErrorMessage}
            </Typography>
          </Box>
        ),
      },
    },
  ]

  const parseAndSetInputs = async (formInputs: any) => {
    const contributionMonthDate = new Date(formInputs.contributionMonth)
    const contributionMonthMonth = contributionMonthDate.getMonth()
    // if (
    //   formInputs.valuationType === Core_ValuationType.ValuationTypeFinal &&
    //   formInputs.currentlyOccupied === 'yes' &&
    //   !currentLeaseUploaded
    // ) {
    //   notify(
    //     'Must upload current lease for final valuation - upload and try again',
    //     'error'
    //   )
    //   return null
    // }
    const propertyVars = valuationInputs?.property as Core_Property
    // for the rental comps, need to get the formatted address from the google autocomplete
    const rentalComps: RentalCompFormInput[] = formInputs.marketRentComps.map(
      (comp: any) => {
        const address =
          comp?.rentCompAddress?.formattedAddress || comp.rentCompAddress
        return {
          ...comp,
          rentCompAddress: address,
        }
      }
    )

    if (formInputs.marketRentCompsPrefill) {
      const prefilledRentComps = getNormalRentCompsFromPrefill(
        formInputs.marketRentCompsPrefill
      )
      rentalComps.push(...prefilledRentComps)
    }
    // need to grab the formatted address from the google autocomplete object
    const offerComps: OfferCompFormInput[] = formInputs.offerPriceComps.map(
      (comp: any) => {
        const address =
          comp?.offerCompAddress?.formattedAddress || comp.offerCompAddress
        return {
          ...comp,
          offerCompAddress: address,
        }
      }
    )

    if (formInputs.offerPriceCompsPrefill) {
      const normalPrefilledOfferComps = getNormalOfferCompsFromPrefill(
        formInputs.offerPriceCompsPrefill
      )
      offerComps.push(...normalPrefilledOfferComps)
    }

    let parsedOfferComps = offerComps.map((comp: any) => ({
      source: comp.offerCompSource,
      similarityScore: comp.similarityScore,
      url: comp.offerCompUrl,
      avmPrice: comp.avmPrice || 0,
      squareFootage: comp.squareFootage,
      address: comp.offerCompAddress,
      beds: comp.beds || 0,
      baths: comp.baths || 0,
      bathsDouble: comp.baths || 0,
      halfBaths: comp.halfBaths || 0,
      yearBuild: comp.yearBuilt,
      soldDate: comp.offerCompSoldDate,
      notes: comp.offerCompNotes,
      addressUuid: offerCompAddressUuidMap.get(comp.offerCompAddress),
      selected: comp.offerCompSelected,
      distance: comp.offerCompDistance,
      conditionScore: comp.conditionScore,
      flatAvmAdjustment: comp.flatAvmAdjustment || 0,
    }))
    let parsedRentComps = rentalComps.map((comp: any) => ({
      source: comp.rentCompSource,
      marketRent: comp.rentCompMarketRent || 0,
      rentalDate: comp.rentCompRentalDate,
      squareFootage: comp.rentCompSquareFootage,
      url: comp.rentCompUrl,
      address: comp.rentCompAddress,
      notes: comp.rentCompNotes,
      addressUuid: rentalCompAddressUuidMap.get(comp.rentCompAddress),
      selected: comp.rentCompSelected,
      distance: comp.rentCompDistance,
    }))

    const errMessage = getCompsValidationErrorMessage(
      Core_ValuationCategory.ValuationCategorySingleFamily,
      parsedRentComps,
      parsedOfferComps,
      setOfferCompsErrorMessage,
      setRentCompsErrorMessage,
      useCma
    )
    if (errMessage) {
      notify(errMessage, 'error')
      return null
    }
    // filters out any comps that are empty (have the default dropdown values), but no other values
    // do this after to checks in case someone actually forgot to put an address in
    parsedOfferComps = parsedOfferComps.filter(
      (comp: Core_OfferValuationCompInput) => comp.address
    )
    parsedRentComps = parsedRentComps.filter(
      (comp: Core_MarketRentCompInput) => comp.address
    )

    let computationDate = null
    if (formInputs.computationDate) {
      computationDate = formInputs.computationDate
    }

    const parsedInputs: Core_ComputeSalesforceAddressValuationRequestInput = {
      ...valuationInputs,
      addressId,
      valuationUuid:
        computedValuationResult?.uuid || valuationInputs?.valuationUuid,
      valuationType: formInputs.valuationType,
      offerPrice: {
        comps: parsedOfferComps,
        housecanaryAdjustedAvmPrice:
          formInputs.housecanaryAdjustedAvmPrice || 0,
        notes: formInputs.offerPriceNotes,
        useCma: formInputs.useCma,
        propertyFlatAvmAdjustment: formInputs.propertyFlatAvmAdjustment || 0,
      },
      remodelCost: {
        score: formInputs.sowCost ? 0 : formInputs.remodelCostScore,
        sowCost: formInputs.sowCost || 0,
        notes: formInputs.remodelNotes,
        healthAndSafetyCapex: formInputs.healthAndSafetyCapex,
        immediateAddressableCapex:
          formInputs.immediateAddressableCapex +
          formInputs.healthAndSafetyCapex,
        totalAddressableCapex:
          formInputs.totalAddressableCapex +
          formInputs.immediateAddressableCapex +
          formInputs.healthAndSafetyCapex,
      },
      marketRent: {
        housecanaryProjectedRent: formInputs.housecanaryProjectedRent || 0,
        comps: parsedRentComps,
        analystProjectedRent: formInputs.analystProjectedRent || 0,
        notes: formInputs.rentNotes,
        currentRent: formInputs.currentRent || 0,
        currentlyOccupied: formInputs.currentlyOccupied === 'yes',
        monthsRemainingOnLease: parseFloat(formInputs.monthsRemainingOnLease),
      },
      otherCosts: {
        acquisition: formInputs.acquisitionCosts || 0,
        miscellaneous: formInputs.miscellaneousCosts || 0,
        hoaExists: formInputs.hoaExists === 'yes',
        hoa: formInputs.yearlyHoaFee || 0,
        addressNearbyHomeHoa: formInputs.nearbyHoaAddress,
        urlNearbyHomeHoa: formInputs.nearbyHoaUrl,
        propertyTaxes: formInputs.taxes || 0,
        notes: formInputs.otherCostNotes,
        additionalMonthlyCost: formInputs.additionalMonthlyCost || 0,
        additionalMonthlyCostNotes: formInputs.additionalMonthlyCostNotes,
        inPlacePropertyTaxes: formInputs.inPlacePropertyTaxes || 0,
      },
      contributionMonth: contributionMonthMonth,
      computationDate,
      isSectionEight: formInputs.isSectionEight === 'yes',
      property: {
        baths: propertyVars?.baths,
        bathsDouble: propertyVars?.baths,
        beds: propertyVars?.beds,
        halfBaths: propertyVars?.halfBaths,
        propertyType: propertyVars?.propertyType,
        sqft: formInputs.sqft || 2000,
        yearBuilt: formInputs.yearBuilt || 1980,
        notes: formInputs.notes || '',
      },
    }
    setValuationInputs(parsedInputs)
    return parsedInputs
  }

  const submitComputeValuation = async (formInputs: any) => {
    const parsedInputs = await parseAndSetInputs(formInputs)
    if (!parsedInputs || Object.keys(parsedInputs).length === 0) {
      return
    }
    try {
      const computedValuationResultResult = await computeValuation({
        variables: {
          computeValuationInput: {
            ...parsedInputs,
            addressId: addressId as string,
            valuationType: formInputs.valuationType as Core_ValuationType,
          },
        },
      })
      setComputedValuationResult(
        computedValuationResultResult?.data?.computeSalesforceAddressValuation
          ?.valuation as any
      )
      // So that the offer price checks render in the form, as the validateFormData won't have
      // the same input avm as what the valuation was computed with.
      if (useCma) {
        setOfferPriceValue(
          // @ts-ignore
          computedValuationResultResult?.data?.computeValuationV1?.valuation
            ?.inputs?.offerPrice?.housecanaryAdjustedAvmPrice as number
        )
      }
    } catch (e) {
      notify('Failed to compute the valuation - refresh and try again', 'error')
    }
  }

  return {
    computeValuationFormFormProps,
    computeValuationFormInputConfigs,
    loading,
    operatorUuid,
    rejectValuationModalOpen,
    setRejectValuationModalOpen,
    valuationHistoryModalOpen,
    setValuationHistoryModalOpen,
    submitComputeValuation,
    computedValuationResult,
    valuationInputs,
    validateFormData,
    canSubmitValuation,
    canRejectValuation,
    selfReportedValue,
    openValuationHistoryModal: () => setValuationHistoryModalOpen(true),
    openRejectValuationModal: () => setRejectValuationModalOpen(true),
    fetchRentalCompsModalOpen,
    setFetchRentalCompsModalOpen,
    formattedAddress,
    parseRentalCompsForPrefill,
    fetchOfferCompsModalOpen,
    setFetchOfferCompsModalOpen,
    parseOfferCompsForPrefill,
    injectedFormValues,
    computeValuationLoading,
    addressId,
  }
}

export default useSingleFamilyComputeAddressValuationTab
