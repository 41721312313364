import { useEffect } from 'react'
import { useForm, useFieldArray, useWatch } from 'react-hook-form'
import { DropResult } from 'react-beautiful-dnd'
import {
  PrintCollateralPageGetSalesforceAccountDocument,
  Core_ValuationType,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { gql, useQuery } from '@apollo/client'
import {
  PrintCollateralPageProps,
  CheckboxOption,
  FormValues,
} from './printCollateralPageTypes'

export const GET_ACCOUNT_INFO = gql`
  query PrintCollateralPageGetSalesforceAccount(
    $input: Core_GetSalesforceAccountRequestInput!
  ) {
    getSalesforceAccount(input: $input) {
      salesforceAccount {
        activeOpportunity {
          salesforceScenarios {
            addresses {
              formattedAddress
              beds
              baths
              propertyCondition
              propertyType
              sqft
              valuation {
                offerComps {
                  formattedAddress
                  beds
                  baths
                  isSelected
                  propertyCondition
                  propertyType
                  soldDate
                  soldPrice
                  sqft
                  yearBuilt
                }
                offerPrice
                valuationType
              }
            }
            cashPaydown
            cashTakeout
            brokerFee
            closingCosts
            offerDate
            offerPrice
            offerPriceHigh
            offerPriceLow
            onboardingFee
            portfolioFcfPercent
            remodelCosts
            showScenario
            valuationType
            submarketRentDeduction
            mortgage
            name
            minimumHoldPeriod
            daysInRenoDeduction
            costOfDebt
          }
        }
        fullName
      }
    }
  }
`

const usePrintCollateralPage = (props: PrintCollateralPageProps) => {
  const { location } = props

  const searchParams = new URLSearchParams(location?.search)
  const accountId = searchParams.get('accountId')

  const { data: salesforceAccount, loading } = useQuery(
    PrintCollateralPageGetSalesforceAccountDocument,
    {
      variables: {
        input: {
          accountId,
        },
      },
      errorPolicy: 'all', // still return data if there are errors for now TODO: fix errors
    }
  )

  const scenarios =
    salesforceAccount?.getSalesforceAccount?.salesforceAccount?.activeOpportunity?.salesforceScenarios?.map(
      (scenario) => ({
        label:
          `${scenario.name} - ${
            scenario.showScenario ? 'active' : 'unactive'
          }` || '',
        value: scenario.name || '',
      })
    ) || []

  const accountName =
    salesforceAccount?.getSalesforceAccount?.salesforceAccount?.fullName

  const {
    control: formControl,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValues>()

  const checkboxOptions: CheckboxOption[] = [
    { id: '1', label: 'Cover', checked: true },
    { id: '2', label: 'Valuation', checked: true },
    { id: '3', label: 'Top Up Cash Flow', checked: true },
    { id: '4', label: 'Allotment Cash Flow', checked: false },
    { id: '5', label: 'Reinvested Cash Flow', checked: false },
    { id: '6', label: 'Valuation Comps', checked: false },
    { id: '7', label: 'FAQs', checked: true },
  ]
  const { fields: rawFields, move } = useFieldArray<FormValues>({
    control: formControl,
    name: 'checkboxes',
  })
  const checkboxFields = rawFields as CheckboxOption[] // useFieldArray somehow not correctly inferring type.

  useEffect(() => {
    setValue('checkboxes', checkboxOptions)
  }, [setValue])

  // Handle drag end event to reorder checkboxes
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return // If dropped outside the list
    move(result.source.index, result.destination.index)
  }

  const onSubmit = handleSubmit((data) => {
    console.log('formData', data)
  })

  const scenarioWatch = useWatch({
    control: formControl,
    name: 'scenario',
  })

  useEffect(() => {
    if (scenarioWatch) {
      const scenarioValuationType =
        salesforceAccount?.getSalesforceAccount?.salesforceAccount?.activeOpportunity?.salesforceScenarios?.find(
          (scenario) => scenario.name === scenarioWatch
        )?.valuationType
      const coverName = `${accountName} Portfolio ${
        scenarioValuationType === Core_ValuationType.ValuationTypeInitial
          ? 'Initial'
          : 'Final'
      } Valuation`
      setValue('coverName', coverName)
    }
  }, [scenarioWatch])

  return {
    accountId,
    accountName,
    scenarios,
    loading,
    formControl,
    onSubmit,
    errors,
    handleDragEnd,
    checkboxFields,
  }
}
export default usePrintCollateralPage
