import { SowProject } from './individualSowProjectPageTypes'

export const mockSfrProject: SowProject[] = [
  {
    uuid: '1234-5678-9012-3457',
    projectName: '1173 Xenon St',
    formattedAddress: '1173 Xenon St, Golden, CO 80401',
    yearBuilt: 1963,
    isMultifamily: false,
    projectStatus: 'in_progress',
    beds: 4,
    baths: 2,
    squareFootage: 2080,
    inspectionDate: new Date('2024-06-05'),
    salesforceBeds: 5,
    salesforceBaths: 2,
    salesforceSquareFootage: 2240,
    salesforceYearBuilt: 1963,
    deficiencies: [
      {
        uuid: '301',
        status: 'pending',
        location: 'Interior',
        category: 'Completion Items',
        deficiency: 'Whole Home Rekey',
        reviewerNotes: 'Install new deadbolts and rekey locks',
        solutions: [
          {
            uuid: '401',
            category: 'Completion Items',
            solution: 'Whole Home Rekey',
            description: 'Install new deadbolts and rekey locks',
            unitOfMeasurement: 'EA',
            unitPrice: 250,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '302',
        status: 'pending',
        location: 'Interior',
        category: 'Completion Items',
        deficiency: 'Completion Package',
        reviewerNotes:
          'Smokes, carbons, light bulbs, door stops and weather stripping',
        solutions: [
          {
            uuid: '402',
            category: 'Completion Items',
            solution: 'Completion Package',
            description:
              'Smokes, carbons, light bulbs, door stops and weather stripping',
            unitOfMeasurement: 'EA',
            unitPrice: 350,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '303',
        status: 'pending',
        location: 'Interior',
        category: 'Completion Items',
        deficiency: 'Interior Clean',
        reviewerNotes: 'Post possession clean',
        solutions: [
          {
            uuid: '403',
            category: 'Completion Items',
            solution: 'Interior Clean',
            description: 'Post possession clean',
            unitOfMeasurement: 'EA',
            unitPrice: 350,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '304',
        status: 'pending',
        location: 'Interior',
        category: 'Windows & Skylights',
        deficiency: 'Interior Windows',
        reviewerNotes: 'Service windows and screens for proper function',
        solutions: [
          {
            uuid: '404',
            category: 'Windows & Skylights',
            solution: 'Interior Windows',
            description: 'Service windows and screens for proper function',
            unitOfMeasurement: 'EA',
            unitPrice: 350,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '305',
        status: 'pending',
        location: 'Interior',
        category: 'Windows & Skylights',
        deficiency: 'Install Blinds',
        reviewerNotes: 'Install new blinds in windows throughout home',
        solutions: [
          {
            uuid: '405',
            category: 'Windows & Skylights',
            solution: 'Install Blinds',
            description: 'Install new blinds in windows throughout home',
            unitOfMeasurement: 'EA',
            unitPrice: 700,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '306',
        status: 'pending',
        location: 'Exterior',
        category: 'Landscaping & Irrigation',
        deficiency: 'Exterior Landscaping',
        reviewerNotes:
          'Medium landscape service, includes trimming growth away from home',
        solutions: [
          {
            uuid: '406',
            category: 'Landscaping & Irrigation',
            solution: 'Exterior Landscaping',
            description:
              'Medium landscape service, includes trimming growth away from home',
            unitOfMeasurement: 'EA',
            unitPrice: 550,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '307',
        status: 'pending',
        location: 'Exterior',
        category: 'Fences & Gates',
        deficiency: 'Exterior Fence',
        reviewerNotes: 'Budget to replace aged/worn fence (wood, privacy)',
        solutions: [
          {
            uuid: '407',
            category: 'Fences & Gates',
            solution: 'Exterior Fence',
            description: 'Budget to replace aged/worn fence (wood, privacy)',
            unitOfMeasurement: 'EA',
            unitPrice: 4700,
            quantity: 1,
            urgency: 'non_immediately_addressable',
            adjustment: 0.5,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '308',
        status: 'pending',
        location: 'Exterior',
        category: 'Other Exterior',
        deficiency: 'Shed',
        reviewerNotes: 'Repair shed door',
        solutions: [
          {
            uuid: '408',
            category: 'Other Exterior',
            solution: 'Shed',
            description: 'Repair shed door',
            unitOfMeasurement: 'EA',
            unitPrice: 75,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '309',
        status: 'pending',
        location: 'Exterior',
        category: 'Demo & Trash Out',
        deficiency: 'Demo',
        reviewerNotes: 'Debris removal from yard',
        solutions: [
          {
            uuid: '409',
            category: 'Demo & Trash Out',
            solution: 'Demo',
            description: 'Debris removal from yard',
            unitOfMeasurement: 'EA',
            unitPrice: 400,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '310',
        status: 'pending',
        location: 'Interior',
        category: 'Electrical',
        deficiency: 'Interior Electrical',
        reviewerNotes:
          'Service outlets, switches and fixtures for proper function. Install GFCIs.',
        solutions: [
          {
            uuid: '410',
            category: 'Electrical',
            solution: 'Interior Electrical',
            description:
              'Service outlets, switches and fixtures for proper function. Install GFCIs.',
            unitOfMeasurement: 'EA',
            unitPrice: 510,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '311',
        status: 'pending',
        location: 'Exterior',
        category: 'Siding',
        deficiency: 'Soffit',
        reviewerNotes: 'Repair soffit',
        solutions: [
          {
            uuid: '411',
            category: 'Siding',
            solution: 'Soffit',
            description: 'Repair soffit',
            unitOfMeasurement: 'EA',
            unitPrice: 75,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '312',
        status: 'pending',
        location: 'Interior',
        category: 'Drywall',
        deficiency: 'Drywall Repairs',
        reviewerNotes: 'Drywall repairs required before paint',
        solutions: [
          {
            uuid: '412',
            category: 'Drywall',
            solution: 'Drywall Repairs',
            description: 'Drywall repairs required before paint',
            unitOfMeasurement: 'EA',
            unitPrice: 350,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '313',
        status: 'pending',
        location: 'Interior',
        category: 'Paint',
        deficiency: 'Interior Paint',
        reviewerNotes: 'Full interior paint required, including primer',
        solutions: [
          {
            uuid: '413',
            category: 'Paint',
            solution: 'Interior Paint',
            description: 'Full interior paint required, including primer',
            unitOfMeasurement: 'EA',
            unitPrice: 7680,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '314',
        status: 'pending',
        location: 'Interior',
        category: 'HVAC',
        deficiency: 'Air Conditioning',
        reviewerNotes:
          'Budget to replace gas furnace and air conditioning system (1990 and 1991)',
        solutions: [
          {
            uuid: '414',
            category: 'HVAC',
            solution: 'Air Conditioning',
            description:
              'Budget to replace gas furnace and air conditioning system (1990 and 1991)',
            unitOfMeasurement: 'EA',
            unitPrice: 6500,
            quantity: 1,
            urgency: 'zero_to_three_years',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '315',
        status: 'pending',
        location: 'Interior',
        category: 'Electrical',
        deficiency: 'Light Fixtures',
        reviewerNotes: 'Replace light fixtures in bedrooms with ceiling fans',
        solutions: [
          {
            uuid: '415',
            category: 'Electrical',
            solution: 'Light Fixtures',
            description: 'Replace light fixtures in bedrooms with ceiling fans',
            unitOfMeasurement: 'EA',
            unitPrice: 875,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '316',
        status: 'pending',
        location: 'Laundry Room',
        category: 'Drywall',
        deficiency: 'Laundry Room Drywall',
        reviewerNotes: 'Repair water-damaged drywall and assess/repair leak',
        solutions: [
          {
            uuid: '416',
            category: 'Drywall',
            solution: 'Laundry Room Drywall',
            description: 'Repair water-damaged drywall and assess/repair leak',
            unitOfMeasurement: 'EA',
            unitPrice: 650,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '317',
        status: 'pending',
        location: 'Laundry Room',
        category: 'Plumbing',
        deficiency: 'Washer Box',
        reviewerNotes: 'Install washer box',
        solutions: [
          {
            uuid: '417',
            category: 'Plumbing',
            solution: 'Washer Box',
            description: 'Install washer box',
            unitOfMeasurement: 'EA',
            unitPrice: 250,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '318',
        status: 'pending',
        location: 'Bedroom 3',
        category: 'Doors',
        deficiency: 'Closet Doors',
        reviewerNotes: 'Install missing closet doors',
        solutions: [
          {
            uuid: '418',
            category: 'Doors',
            solution: 'Closet Doors',
            description: 'Install missing closet doors',
            unitOfMeasurement: 'EA',
            unitPrice: 250,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '319',
        status: 'pending',
        location: 'Interior',
        category: 'Plumbing',
        deficiency: 'General Plumbing',
        reviewerNotes:
          'Drain stops. Snake drains. Caulk wet areas. Replace toilet seats.',
        solutions: [
          {
            uuid: '419',
            category: 'Plumbing',
            solution: 'General Plumbing',
            description:
              'Drain stops. Snake drains. Caulk wet areas. Replace toilet seats.',
            unitOfMeasurement: 'EA',
            unitPrice: 370,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '320',
        status: 'pending',
        location: 'Interior',
        category: 'Flooring',
        deficiency: 'Tile Cleaning',
        reviewerNotes: 'Steam clean all tile',
        solutions: [
          {
            uuid: '420',
            category: 'Flooring',
            solution: 'Tile Cleaning',
            description: 'Steam clean all tile',
            unitOfMeasurement: 'EA',
            unitPrice: 300,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '321',
        status: 'pending',
        location: 'Bath 1',
        category: 'Plumbing',
        deficiency: 'Bathroom Upgrades',
        reviewerNotes:
          'Add privacy film to shower window panes. Replace toilet, mirror, and faucet. Install and properly vent exhaust fan.',
        solutions: [
          {
            uuid: '421',
            category: 'Plumbing',
            solution: 'Bathroom Upgrades',
            description:
              'Add privacy film to shower window panes. Replace toilet, mirror, and faucet. Install and properly vent exhaust fan.',
            unitOfMeasurement: 'EA',
            unitPrice: 900,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '322',
        status: 'pending',
        location: 'Bath 2',
        category: 'Doors',
        deficiency: 'Door Replacement',
        reviewerNotes: 'Replace door slab',
        solutions: [
          {
            uuid: '422',
            category: 'Doors',
            solution: 'Door Replacement',
            description: 'Replace door slab',
            unitOfMeasurement: 'EA',
            unitPrice: 125,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '323',
        status: 'pending',
        location: 'Exterior',
        category: 'Paint',
        deficiency: 'Exterior Paint',
        reviewerNotes: 'Budget to repaint wood siding and trim (whole house)',
        solutions: [
          {
            uuid: '423',
            category: 'Paint',
            solution: 'Exterior Paint',
            description: 'Budget to repaint wood siding and trim (whole house)',
            unitOfMeasurement: 'EA',
            unitPrice: 5250,
            quantity: 1,
            urgency: 'non_immediately_addressable',
            adjustment: 0.75,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '324',
        status: 'pending',
        location: 'Interior',
        category: 'Flooring',
        deficiency: 'Baseboard Replacement',
        reviewerNotes: 'Replace baseboards in all carpeted areas',
        solutions: [
          {
            uuid: '424',
            category: 'Flooring',
            solution: 'Baseboard Replacement',
            description: 'Replace baseboards in all carpeted areas',
            unitOfMeasurement: 'EA',
            unitPrice: 450,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '325',
        status: 'pending',
        location: 'Interior',
        category: 'Flooring',
        deficiency: 'Carpet Replacement',
        reviewerNotes: 'Replace carpet with LVP and quarter round',
        solutions: [
          {
            uuid: '425',
            category: 'Flooring',
            solution: 'Carpet Replacement',
            description: 'Replace carpet with LVP and quarter round',
            unitOfMeasurement: 'EA',
            unitPrice: 6600,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '326',
        status: 'pending',
        location: 'Kitchen',
        category: 'Cabinets & Countertops',
        deficiency: 'Countertop Replacement',
        reviewerNotes:
          'Replace countertops with solid surface, including undermount sink and faucet',
        solutions: [
          {
            uuid: '426',
            category: 'Cabinets & Countertops',
            solution: 'Countertop Replacement',
            description:
              'Replace countertops with solid surface, including undermount sink and faucet',
            unitOfMeasurement: 'EA',
            unitPrice: 2325,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '327',
        status: 'pending',
        location: 'Kitchen',
        category: 'Appliances',
        deficiency: 'Dishwasher Replacement',
        reviewerNotes: 'Budget to replace dishwasher (2007)',
        solutions: [
          {
            uuid: '427',
            category: 'Appliances',
            solution: 'Dishwasher Replacement',
            description: 'Budget to replace dishwasher (2007)',
            unitOfMeasurement: 'EA',
            unitPrice: 850,
            quantity: 1,
            urgency: 'zero_to_three_years',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '328',
        status: 'pending',
        location: 'Kitchen',
        category: 'Appliances',
        deficiency: 'Refrigerator Replacement',
        reviewerNotes: 'Budget to replace refrigerator (2007)',
        solutions: [
          {
            uuid: '428',
            category: 'Appliances',
            solution: 'Refrigerator Replacement',
            description: 'Budget to replace refrigerator (2007)',
            unitOfMeasurement: 'EA',
            unitPrice: 1425,
            quantity: 1,
            urgency: 'zero_to_three_years',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '329',
        status: 'pending',
        location: 'Kitchen',
        category: 'Appliances',
        deficiency: 'Range Replacement',
        reviewerNotes: 'Budget to replace electric range (2007)',
        solutions: [
          {
            uuid: '429',
            category: 'Appliances',
            solution: 'Range Replacement',
            description: 'Budget to replace electric range (2007)',
            unitOfMeasurement: 'EA',
            unitPrice: 990,
            quantity: 1,
            urgency: 'zero_to_three_years',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '330',
        status: 'pending',
        location: 'Kitchen',
        category: 'Appliances',
        deficiency: 'Hood Replacement',
        reviewerNotes: 'Budget to replace hood',
        solutions: [
          {
            uuid: '430',
            category: 'Appliances',
            solution: 'Hood Replacement',
            description: 'Budget to replace hood',
            unitOfMeasurement: 'EA',
            unitPrice: 150,
            quantity: 1,
            urgency: 'zero_to_three_years',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
    ],
    assets: [
      {
        uuid: '13513513673',
        sowProjectUuid: '1234-5678-9012-3457',
        status: 'in_progress',
        location: 'Kitchen',
        category: 'Appliances',
        item: 'Dishwasher',
        month: 11,
        year: 2021,
        media: [],
        endOfLifeDate: new Date('2023-12-31'),
      },
      // Make one for the range and refrigerator
      {
        uuid: '13513513674',
        sowProjectUuid: '1234-5678-9012-3457',
        status: 'in_progress',
        location: 'Kitchen',
        category: 'Appliances',
        item: 'Range',
        month: 11,
        year: 2008,
        media: [],
        endOfLifeDate: new Date('2023-12-31'),
      },
      {
        uuid: '13513513675',
        sowProjectUuid: '1234-5678-9012-3457',
        status: 'in_progress',
        location: 'Kitchen',
        category: 'Appliances',
        item: 'Refrigerator',
        month: 11,
        year: 2014,
        media: [],
        endOfLifeDate: new Date('2023-12-31'),
      },
    ],
  },
]

export const mockMultifamilyProject: SowProject[] = [
  {
    uuid: '1234-5678-9012-3456',
    projectName: '620 & 630 Ingalls St - Shared/Exterior',
    projectStatus: 'in_progress',
    formattedAddress: '620 & 630 Ingalls St, Lakewood, CO 80214',
    yearBuilt: 1963,
    isMultifamily: true,
    beds: 5,
    baths: 3,
    squareFootage: 2240,
    inspectionDate: new Date('2024-06-20'),
    salesforceBeds: 4,
    salesforceBaths: 3,
    salesforceSquareFootage: 2240,
    salesforceYearBuilt: 1963,
    deficiencies: [
      {
        uuid: 'def1-2345-6789-0123',
        status: 'Open',
        location: 'Exterior',
        category: 'Gutters',
        deficiency: 'Unsecured downspout and missing extensions',
        media: [
          {
            uuid: 'med1-2345-6789-0123',
            s3Url:
              'https://flock-address-photos.s3.us-east-2.amazonaws.com/production/0b11eec9-b747-4011-b45e-aae254f22c01',
          },
        ],
        reviewerNotes: '',
        solutions: [
          {
            uuid: 'sol1-2345-6789-0123',
            category: 'Gutters',
            solution: 'Secure downspout and add extensions',
            description: 'Secure downspout and add downspout extensions',
            unitOfMeasurement: 'LS',
            unitPrice: 75,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1,
            itemCode: 'GUT001',
          },
        ],
      },
      {
        uuid: 'def2-3456-7890-1234',
        status: 'Open',
        location: 'Exterior',
        category: 'Paint',
        deficiency: 'Fascia and trim need painting',
        media: [
          {
            uuid: 'med2-3456-7890-1234',
            s3Url:
              'https://flock-address-photos.s3.us-east-2.amazonaws.com/production/c410aa79-6a4b-4c39-9f25-7ed4369e10e0',
          },
        ],
        reviewerNotes: '',
        solutions: [
          {
            uuid: 'sol2-3456-7890-1234',
            category: 'Paint',
            solution: 'Paint fascia and trim',
            description: 'Paint fascia and trim',
            unitOfMeasurement: 'LS',
            unitPrice: 850,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1,
            itemCode: 'PNT001',
          },
        ],
      },
      {
        uuid: 'def3-4567-8901-2345',
        status: 'Open',
        location: 'Exterior',
        category: 'Structural',
        deficiency: 'Loose exterior wall structure and uncaulked fascia',
        media: [
          {
            uuid: 'med3-4567-8901-2345',
            s3Url:
              'https://flock-address-photos.s3.us-east-2.amazonaws.com/production/919dba0c-7681-42a2-be99-9f8cb72c6fe0',
          },
        ],
        reviewerNotes: '',
        solutions: [
          {
            uuid: 'sol3-4567-8901-2345',
            category: 'Structural',
            solution: 'Secure loose structure and caulk fascia',
            description:
              'Re-caulk exterior fascia for waterproofing. Secure loose exterior wall structure.',
            unitOfMeasurement: 'LS',
            unitPrice: 5500,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1,
            itemCode: 'STR001',
          },
        ],
      },
      {
        uuid: 'def4-5678-9012-3456',
        status: 'Open',
        location: 'Interior',
        category: 'Plumbing',
        deficiency: 'Aging water heater',
        media: [],
        reviewerNotes: '75 gallon gas water heater from 2012',
        solutions: [
          {
            uuid: 'sol4-5678-9012-3456',
            category: 'Plumbing',
            solution: 'Replace water heater',
            description: 'Replace 75 gallon gas water heater (2012)',
            unitOfMeasurement: 'EA',
            unitPrice: 3500,
            quantity: 1,
            urgency: 'zero_to_three_years',
            adjustment: 1,
            itemCode: 'PLM001',
          },
        ],
      },
      {
        uuid: 'def5-6789-0123-4567',
        status: 'Open',
        location: 'Interior',
        category: 'HVAC',
        deficiency: 'Aging HVAC system',
        media: [],
        reviewerNotes: 'Weil-McLain Boiler system from 2003',
        solutions: [
          {
            uuid: 'sol5-6789-0123-4567',
            category: 'HVAC',
            solution: 'Service and budget for replacement',
            description: 'Budget to replace.',
            unitOfMeasurement: 'LS',
            unitPrice: 7500,
            quantity: 1,
            urgency: 'non_immediately_addressable',
            adjustment: 0.5,
            itemCode: 'HVC001',
          },
          {
            uuid: 'sol5-6789-0123-4567',
            category: 'HVAC',
            solution: 'HVAC Filter Servicing',
            description: 'Service Weil-McLain Boiler system (2003).',
            unitOfMeasurement: 'LS',
            unitPrice: 500,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1,
            itemCode: '',
          },
        ],
      },
    ],
    assets: [
      {
        uuid: '13513513673',
        sowProjectUuid: '1234-5678-9012-3457',
        status: 'in_progress',
        location: 'Kitchen',
        category: 'Appliances',
        item: 'Dishwasher',
        month: 11,
        year: 2021,
        media: [],
        endOfLifeDate: new Date('2023-12-31'),
      },
      // Make one for the range and refrigerator
      {
        uuid: '13513513674',
        sowProjectUuid: '1234-5678-9012-3457',
        status: 'in_progress',
        location: 'Kitchen',
        category: 'Appliances',
        item: 'Range',
        month: 11,
        year: 2008,
        media: [],
        endOfLifeDate: new Date('2023-12-31'),
      },
      {
        uuid: '13513513675',
        sowProjectUuid: '1234-5678-9012-3457',
        status: 'in_progress',
        location: 'Kitchen',
        category: 'Appliances',
        item: 'Refrigerator',
        month: 11,
        year: 2014,
        media: [],
        endOfLifeDate: new Date('2023-12-31'),
      },
    ],
  },
  {
    uuid: '2345-6789-0123-4567',
    projectName: '620 Ingalls St',
    formattedAddress: '620 Ingalls St, Lakewood, CO 80214',
    projectStatus: 'in_progress',
    yearBuilt: 1963,
    isMultifamily: false,
    beds: 2,
    baths: 1,
    squareFootage: 810,
    inspectionDate: new Date('2024-06-20'),
    salesforceBeds: 2,
    salesforceBaths: 1,
    salesforceSquareFootage: 810,
    salesforceYearBuilt: 1963,
    deficiencies: [
      {
        uuid: '1',
        status: 'pending',
        location: 'Interior',
        category: 'Completion Items',
        deficiency: 'Completion Package',
        reviewerNotes:
          'Smokes, carbons, light bulbs, door stops and weather stripping',
        solutions: [
          {
            uuid: '1',
            category: 'Completion Items',
            solution: 'Completion Package',
            description:
              'Smokes, carbons, light bulbs, door stops and weather stripping',
            unitOfMeasurement: 'EA',
            unitPrice: 350,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '2',
        status: 'pending',
        location: 'Interior',
        category: 'Completion Items',
        deficiency: 'Whole Home Rekey',
        reviewerNotes: 'Install new deadbolts and rekey locks',
        solutions: [
          {
            uuid: '2',
            category: 'Completion Items',
            solution: 'Whole Home Rekey',
            description: 'Install new deadbolts and rekey locks',
            unitOfMeasurement: 'EA',
            unitPrice: 250,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '3',
        status: 'pending',
        location: 'Interior',
        category: 'Completion Items',
        deficiency: 'Interior Clean',
        reviewerNotes: 'Post possession clean',
        solutions: [
          {
            uuid: '3',
            category: 'Completion Items',
            solution: 'Interior Clean',
            description: 'Post possession clean',
            unitOfMeasurement: 'EA',
            unitPrice: 350,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '4',
        status: 'pending',
        location: 'Interior',
        category: 'Plumbing',
        deficiency: 'Interior Plumbing',
        reviewerNotes: 'Drains, angle stops and supply lines. Snake drains.',
        solutions: [
          {
            uuid: '4',
            category: 'Plumbing',
            solution: 'Interior Plumbing',
            description: 'Drains, angle stops and supply lines. Snake drains.',
            unitOfMeasurement: 'EA',
            unitPrice: 350,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '5',
        status: 'pending',
        location: 'Interior',
        category: 'Doors',
        deficiency: 'Interior Doors',
        reviewerNotes: 'Service doors and hardware for proper function',
        solutions: [
          {
            uuid: '5',
            category: 'Doors',
            solution: 'Interior Doors',
            description: 'Service doors and hardware for proper function',
            unitOfMeasurement: 'EA',
            unitPrice: 250,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '6',
        status: 'pending',
        location: 'Interior',
        category: 'Windows & Skylights',
        deficiency: 'Interior Windows',
        reviewerNotes:
          'Service windows, screens and blinds for proper function',
        solutions: [
          {
            uuid: '6',
            category: 'Windows & Skylights',
            solution: 'Interior Windows',
            description:
              'Service windows, screens and blinds for proper function',
            unitOfMeasurement: 'EA',
            unitPrice: 350,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '7',
        status: 'pending',
        location: 'Interior',
        category: 'Electrical',
        deficiency: 'Service outlets, switches, and fixtures',
        reviewerNotes: '',
        solutions: [
          {
            uuid: '7',
            category: 'Electrical',
            solution: 'Service outlets, switches, and fixtures',
            description: 'Service outlets, switches, and fixtures',
            unitOfMeasurement: 'EA',
            unitPrice: 350,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '8',
        status: 'pending',
        location: 'Exterior',
        category: 'Electrical',
        deficiency: 'Replace exterior light at back door',
        reviewerNotes: '',
        solutions: [
          {
            uuid: '8',
            category: 'Electrical',
            solution: 'Replace exterior light at back door',
            description: 'Replace exterior light at back door',
            unitOfMeasurement: 'EA',
            unitPrice: 100,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '9',
        status: 'pending',
        location: 'Garage',
        category: 'Interior Floor',
        deficiency: 'Grind concrete to eliminate trip hazard and seal',
        reviewerNotes: '',
        solutions: [
          {
            uuid: '9',
            category: 'Garage',
            solution: 'Grind concrete to eliminate trip hazard and seal',
            description: 'Grind concrete to eliminate trip hazard and seal',
            unitOfMeasurement: 'EA',
            unitPrice: 150,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '10',
        status: 'pending',
        location: 'Exterior',
        category: 'Paint',
        deficiency: 'Paint exterior doors and frames',
        reviewerNotes: '',
        solutions: [
          {
            uuid: '10',
            category: 'Paint',
            solution: 'Paint exterior doors and frames',
            description: 'Paint exterior doors and frames',
            unitOfMeasurement: 'EA',
            unitPrice: 500,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '11',
        status: 'pending',
        location: 'Garage',
        category: 'Drywall',
        deficiency: 'Patch holes in firewall in garage',
        reviewerNotes: '',
        solutions: [
          {
            uuid: '11',
            category: 'Drywall',
            solution: 'Patch holes in firewall in garage',
            description: 'Patch holes in firewall in garage',
            unitOfMeasurement: 'EA',
            unitPrice: 125,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '12',
        status: 'pending',
        location: 'Interior',
        category: 'Paint',
        deficiency: 'Touch-up paint required',
        reviewerNotes: '',
        solutions: [
          {
            uuid: '12',
            category: 'Paint',
            solution: 'Touch-up paint required',
            description: 'Touch-up paint required',
            unitOfMeasurement: 'EA',
            unitPrice: 200,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '13',
        status: 'pending',
        location: 'Interior',
        category: 'Paint',
        deficiency: 'Full interior paint required',
        reviewerNotes: '',
        solutions: [
          {
            uuid: '13',
            category: 'Paint',
            solution: 'Full interior paint required',
            description: 'Full interior paint required',
            unitOfMeasurement: 'EA',
            unitPrice: 2835,
            quantity: 1,
            urgency: 'non_immediately_addressable',
            adjustment: 0.75,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '14',
        status: 'pending',
        location: 'Interior',
        category: 'Flooring',
        deficiency: 'Refresh wood floors',
        reviewerNotes: '',
        solutions: [
          {
            uuid: '14',
            category: 'Flooring',
            solution: 'Refresh wood floors',
            description: 'Refresh wood floors',
            unitOfMeasurement: 'EA',
            unitPrice: 2240,
            quantity: 1,
            urgency: 'zero_to_three_years',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '15',
        status: 'pending',
        location: 'Interior',
        category: 'Flooring',
        deficiency: 'Replace vinyl flooring with LVP and trim',
        reviewerNotes: '',
        solutions: [
          {
            uuid: '15',
            category: 'Flooring',
            solution: 'Replace vinyl flooring with LVP and trim',
            description: 'Replace vinyl flooring with LVP and trim',
            unitOfMeasurement: 'EA',
            unitPrice: 935,
            quantity: 1,
            urgency: 'non_immediately_addressable',
            adjustment: 0.5,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '16',
        status: 'pending',
        location: 'Interior',
        category: 'Cabinets & Countertops',
        deficiency: 'Kitchen Cabinets',
        reviewerNotes:
          'Paint upper cabinets. Replace hardware to match lower cabinets.',
        solutions: [
          {
            uuid: '16',
            category: 'Cabinets & Countertops',
            solution: 'Kitchen Cabinets',
            description:
              'Paint upper cabinets. Replace hardware to match lower cabinets.',
            unitOfMeasurement: 'EA',
            unitPrice: 1050,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '17',
        status: 'pending',
        location: 'Interior',
        category: 'Cabinets & Countertops',
        deficiency: 'Kitchen Countertops',
        reviewerNotes: 'Replace cabinet above range for proper clearance',
        solutions: [
          {
            uuid: '17',
            category: 'Cabinets & Countertops',
            solution: 'Kitchen Countertops',
            description: 'Replace cabinet above range for proper clearance',
            unitOfMeasurement: 'EA',
            unitPrice: 400,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '18',
        status: 'pending',
        location: 'Interior',
        category: 'Appliances',
        deficiency: 'Refrigerator',
        reviewerNotes: 'Replace refrigerator with french door stainless steel',
        solutions: [
          {
            uuid: '18',
            category: 'Appliances',
            solution: 'Refrigerator',
            description:
              'Replace refrigerator with french door stainless steel',
            unitOfMeasurement: 'EA',
            unitPrice: 1425,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '19',
        status: 'pending',
        location: 'Interior',
        category: 'Appliances',
        deficiency: 'Microwave',
        reviewerNotes:
          'Replace damaged microwave over range with stainless steel',
        solutions: [
          {
            uuid: '19',
            category: 'Appliances',
            solution: 'Microwave',
            description:
              'Replace damaged microwave over range with stainless steel',
            unitOfMeasurement: 'EA',
            unitPrice: 650,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '20',
        status: 'pending',
        location: 'Interior',
        category: 'Appliances',
        deficiency: 'Range',
        reviewerNotes: 'Replace range (2014) with stainless steel',
        solutions: [
          {
            uuid: '20',
            category: 'Appliances',
            solution: 'Range',
            description: 'Replace range (2014) with stainless steel',
            unitOfMeasurement: 'EA',
            unitPrice: 990,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '21',
        status: 'pending',
        location: 'Interior',
        category: 'Appliances',
        deficiency: 'Dishwasher',
        reviewerNotes: 'Budget to replace dishwasher (2019)',
        solutions: [
          {
            uuid: '21',
            category: 'Appliances',
            solution: 'Dishwasher',
            description: 'Budget to replace dishwasher (2019)',
            unitOfMeasurement: 'EA',
            unitPrice: 850,
            quantity: 1,
            urgency: 'non_immediately_addressable',
            adjustment: 0.5,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '22',
        status: 'pending',
        location: 'Interior',
        category: 'Plumbing',
        deficiency: 'Interior Bath 1',
        reviewerNotes: 'Acid wash tub',
        solutions: [
          {
            uuid: '22',
            category: 'Plumbing',
            solution: 'Interior Bath 1',
            description: 'Acid wash tub',
            unitOfMeasurement: 'EA',
            unitPrice: 125,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '23',
        status: 'pending',
        location: 'Exterior',
        category: 'Landscaping & Irrigation',
        deficiency: 'Exterior Landscaping',
        reviewerNotes: 'Light landscape service',
        solutions: [
          {
            uuid: '23',
            category: 'Landscaping & Irrigation',
            solution: 'Exterior Landscaping',
            description: 'Light landscape service',
            unitOfMeasurement: 'EA',
            unitPrice: 350,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '24',
        status: 'pending',
        location: 'Exterior',
        category: 'Siding',
        deficiency: 'Exterior Trim',
        reviewerNotes: 'Replace damaged wood trim over front door',
        solutions: [
          {
            uuid: '24',
            category: 'Siding',
            solution: 'Exterior Trim',
            description: 'Replace damaged wood trim over front door',
            unitOfMeasurement: 'EA',
            unitPrice: 250,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '25',
        status: 'pending',
        location: 'Driveways & Walkways',
        category: 'Concrete',
        deficiency: 'Replace front stoop',
        reviewerNotes: 'Budget to replace front stoop',
        solutions: [
          {
            uuid: '25',
            category: 'Driveways & Walkways',
            solution: 'Concrete',
            description: 'Budget to replace front stoop',
            unitOfMeasurement: 'EA',
            unitPrice: 480,
            quantity: 1,
            urgency: 'non_immediately_addressable',
            adjustment: 0.75,
            itemCode: '',
          },
        ],
        media: [],
      },
    ],
    assets: [
      {
        uuid: '13513513673',
        sowProjectUuid: '1234-5678-9012-3457',
        status: 'in_progress',
        location: 'Kitchen',
        category: 'Appliances',
        item: 'Dishwasher',
        month: 11,
        year: 2021,
        media: [],
        endOfLifeDate: new Date('2023-12-31'),
      },
      // Make one for the range and refrigerator
      {
        uuid: '13513513674',
        sowProjectUuid: '1234-5678-9012-3457',
        status: 'in_progress',
        location: 'Kitchen',
        category: 'Appliances',
        item: 'Range',
        month: 11,
        year: 2008,
        media: [],
        endOfLifeDate: new Date('2023-12-31'),
      },
      {
        uuid: '13513513675',
        sowProjectUuid: '1234-5678-9012-3457',
        status: 'in_progress',
        location: 'Kitchen',
        category: 'Appliances',
        item: 'Refrigerator',
        month: 11,
        year: 2014,
        media: [],
        endOfLifeDate: new Date('2023-12-31'),
      },
    ],
  },
  {
    uuid: '3456-7890-1234-5678',
    projectName: '630 Ingalls St',
    projectStatus: 'in_progress',
    formattedAddress: '630 Ingalls St, Lakewood, CO 80214',
    yearBuilt: 1963,
    isMultifamily: false,
    beds: 2,
    baths: 2,
    squareFootage: 1430,
    inspectionDate: new Date('2024-06-20'),
    salesforceBeds: 2,
    salesforceBaths: 2,
    salesforceSquareFootage: 1430,
    salesforceYearBuilt: 1963,
    deficiencies: [
      {
        uuid: '101',
        status: 'pending',
        location: 'Interior',
        category: 'Completion Items',
        deficiency: 'Completion Package',
        reviewerNotes:
          'Smokes, carbons, light bulbs, door stops and weather stripping',
        solutions: [
          {
            uuid: '201',
            category: 'Completion Items',
            solution: 'Completion Package',
            description:
              'Smokes, carbons, light bulbs, door stops and weather stripping',
            unitOfMeasurement: 'EA',
            unitPrice: 350,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '102',
        status: 'pending',
        location: 'Interior',
        category: 'Completion Items',
        deficiency: 'Whole Home Rekey',
        reviewerNotes: 'Install new deadbolts and rekey locks',
        solutions: [
          {
            uuid: '202',
            category: 'Completion Items',
            solution: 'Whole Home Rekey',
            description: 'Install new deadbolts and rekey locks',
            unitOfMeasurement: 'EA',
            unitPrice: 250,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '103',
        status: 'pending',
        location: 'Interior',
        category: 'Completion Items',
        deficiency: 'Interior Clean',
        reviewerNotes: 'Post possession clean',
        solutions: [
          {
            uuid: '203',
            category: 'Completion Items',
            solution: 'Interior Clean',
            description: 'Post possession clean',
            unitOfMeasurement: 'EA',
            unitPrice: 350,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '104',
        status: 'pending',
        location: 'Interior',
        category: 'Plumbing',
        deficiency: 'Interior Plumbing',
        reviewerNotes: 'Drains, angle stops and supply lines. Snake drains.',
        solutions: [
          {
            uuid: '204',
            category: 'Plumbing',
            solution: 'Interior Plumbing',
            description: 'Drains, angle stops and supply lines. Snake drains.',
            unitOfMeasurement: 'EA',
            unitPrice: 350,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '105',
        status: 'pending',
        location: 'Interior',
        category: 'Doors',
        deficiency: 'Interior Doors',
        reviewerNotes: 'Service doors and hardware for proper function',
        solutions: [
          {
            uuid: '205',
            category: 'Doors',
            solution: 'Interior Doors',
            description: 'Service doors and hardware for proper function',
            unitOfMeasurement: 'EA',
            unitPrice: 250,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '106',
        status: 'pending',
        location: 'Exterior',
        category: 'Doors',
        deficiency: 'Exterior Doors',
        reviewerNotes: 'Replace front storm door',
        solutions: [
          {
            uuid: '206',
            category: 'Doors',
            solution: 'Exterior Doors',
            description: 'Replace front storm door',
            unitOfMeasurement: 'EA',
            unitPrice: 275,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '107',
        status: 'pending',
        location: 'Exterior',
        category: 'Paint',
        deficiency: 'Paint - Front Door',
        reviewerNotes: 'Paint front door and frame',
        solutions: [
          {
            uuid: '207',
            category: 'Paint',
            solution: 'Paint - Front Door',
            description: 'Paint front door and frame',
            unitOfMeasurement: 'EA',
            unitPrice: 250,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '108',
        status: 'pending',
        location: 'Interior',
        category: 'Windows & Skylights',
        deficiency: 'Interior Windows',
        reviewerNotes:
          'Service windows, screens and blinds for proper function',
        solutions: [
          {
            uuid: '208',
            category: 'Windows & Skylights',
            solution: 'Interior Windows',
            description:
              'Service windows, screens and blinds for proper function',
            unitOfMeasurement: 'EA',
            unitPrice: 350,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '109',
        status: 'pending',
        location: 'Exterior',
        category: 'Windows & Skylights',
        deficiency: 'Exterior Windows',
        reviewerNotes: 'Replace broken window pane in garage',
        solutions: [
          {
            uuid: '209',
            category: 'Windows & Skylights',
            solution: 'Exterior Windows',
            description: 'Replace broken window pane in garage',
            unitOfMeasurement: 'EA',
            unitPrice: 350,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '110',
        status: 'pending',
        location: 'Interior',
        category: 'Electrical',
        deficiency: 'Electrical Code Compliance',
        reviewerNotes: 'Bring electrical to code in basement, garage, and shed',
        solutions: [
          {
            uuid: '210',
            category: 'Electrical',
            solution: 'Electrical Code Compliance',
            description:
              'Bring electrical to code in basement, garage, and shed',
            unitOfMeasurement: 'EA',
            unitPrice: 750,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '111',
        status: 'pending',
        location: 'Interior',
        category: 'Electrical',
        deficiency: 'Replace Outlets and Switches',
        reviewerNotes: '',
        solutions: [
          {
            uuid: '211',
            category: 'Electrical',
            solution: 'Replace Outlets and Switches',
            description: 'Replace all outlets and switches',
            unitOfMeasurement: 'EA',
            unitPrice: 450,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '112',
        status: 'pending',
        location: 'Exterior',
        category: 'Decks & Porches',
        deficiency: 'Back Deck',
        reviewerNotes:
          'Secure deck boards. Add handrail on steps to bring to code.',
        solutions: [
          {
            uuid: '212',
            category: 'Decks & Porches',
            solution: 'Back Deck',
            description:
              'Secure deck boards. Add handrail on steps to bring to code.',
            unitOfMeasurement: 'EA',
            unitPrice: 550,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '113',
        status: 'pending',
        location: 'Interior',
        category: 'Electrical',
        deficiency: 'Electrical Panel',
        reviewerNotes: 'Replace recalled electrical panel',
        solutions: [
          {
            uuid: '213',
            category: 'Electrical',
            solution: 'Electrical Panel',
            description: 'Replace recalled electrical panel',
            unitOfMeasurement: 'EA',
            unitPrice: 2350,
            quantity: 1,
            urgency: 'health_and_safety',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '114',
        status: 'pending',
        location: 'Exterior',
        category: 'Electrical',
        deficiency: 'Exterior Light Fixture',
        reviewerNotes: 'Replace exterior light at front door',
        solutions: [
          {
            uuid: '214',
            category: 'Electrical',
            solution: 'Exterior Light Fixture',
            description: 'Replace exterior light at front door',
            unitOfMeasurement: 'EA',
            unitPrice: 150,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '115',
        status: 'pending',
        location: 'Interior',
        category: 'Paint',
        deficiency: 'Interior Paint',
        reviewerNotes:
          'Full interior paint required - Primer added for paneling',
        solutions: [
          {
            uuid: '215',
            category: 'Paint',
            solution: 'Interior Paint',
            description:
              'Full interior paint required - Primer added for paneling',
            unitOfMeasurement: 'EA',
            unitPrice: 5160,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '116',
        status: 'pending',
        location: 'Interior',
        category: 'Flooring',
        deficiency: 'Interior Flooring',
        reviewerNotes:
          'Refresh wood floors. Add LVP with trim over vinyl floors',
        solutions: [
          {
            uuid: '216',
            category: 'Flooring',
            solution: 'Interior Flooring',
            description:
              'Refresh wood floors. Add LVP with trim over vinyl floors',
            unitOfMeasurement: 'EA',
            unitPrice: 3175,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '117',
        status: 'pending',
        location: 'Interior',
        category: 'Plumbing',
        deficiency: 'Interior Bath 1',
        reviewerNotes:
          'Replace vanity, faucet, and mirror. Resurface tub and surround. Add bath accessories. Install new exhaust fan, including ducting and new circuit.',
        solutions: [
          {
            uuid: '217',
            category: 'Plumbing',
            solution: 'Interior Bath 1',
            description:
              'Replace vanity, faucet, and mirror. Resurface tub and surround. Add bath accessories. Install new exhaust fan, including ducting and new circuit.',
            unitOfMeasurement: 'EA',
            unitPrice: 2125,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '118',
        status: 'pending',
        location: 'Interior',
        category: 'Plumbing',
        deficiency: 'Interior Bath 2',
        reviewerNotes:
          'Acid wash shower floor. Install new exhaust fan in bathroom, including ducting and new circuit',
        solutions: [
          {
            uuid: '218',
            category: 'Plumbing',
            solution: 'Interior Bath 2',
            description:
              'Acid wash shower floor. Install new exhaust fan in bathroom, including ducting and new circuit',
            unitOfMeasurement: 'EA',
            unitPrice: 700,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '119',
        status: 'pending',
        location: 'Basement',
        category: 'Plumbing',
        deficiency: 'Plumbing - Basement',
        reviewerNotes: 'Replace faucet on utility sink',
        solutions: [
          {
            uuid: '219',
            category: 'Plumbing',
            solution: 'Plumbing - Basement',
            description: 'Replace faucet on utility sink',
            unitOfMeasurement: 'EA',
            unitPrice: 150,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '120',
        status: 'pending',
        location: 'Basement',
        category: 'Doors',
        deficiency: 'Interior Doors',
        reviewerNotes: 'Replace (2) basement interior door slabs',
        solutions: [
          {
            uuid: '220',
            category: 'Doors',
            solution: 'Interior Doors',
            description: 'Replace (2) basement interior door slabs',
            unitOfMeasurement: 'EA',
            unitPrice: 300,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '121',
        status: 'pending',
        location: 'Kitchen',
        category: 'Cabinets & Countertops',
        deficiency: 'Kitchen Cabinets',
        reviewerNotes: 'Budget to paint cabinets and add hardware',
        solutions: [
          {
            uuid: '221',
            category: 'Cabinets & Countertops',
            solution: 'Kitchen Cabinets',
            description: 'Budget to paint cabinets and add hardware',
            unitOfMeasurement: 'EA',
            unitPrice: 1450,
            quantity: 1,
            urgency: 'zero_to_three_years',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '122',
        status: 'pending',
        location: 'Kitchen',
        category: 'Cabinets & Countertops',
        deficiency: 'Kitchen Countertops',
        reviewerNotes:
          'Budget to replace countertops with solid surface, including undermount sink and faucet',
        solutions: [
          {
            uuid: '222',
            category: 'Cabinets & Countertops',
            solution: 'Kitchen Countertops',
            description:
              'Budget to replace countertops with solid surface, including undermount sink and faucet',
            unitOfMeasurement: 'EA',
            unitPrice: 2400,
            quantity: 1,
            urgency: 'zero_to_three_years',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '123',
        status: 'pending',
        location: 'Kitchen',
        category: 'Appliances',
        deficiency: 'Dishwasher',
        reviewerNotes: 'Budget to replace dishwasher with stainless steel',
        solutions: [
          {
            uuid: '223',
            category: 'Appliances',
            solution: 'Dishwasher',
            description: 'Budget to replace dishwasher with stainless steel',
            unitOfMeasurement: 'EA',
            unitPrice: 850,
            quantity: 1,
            urgency: 'zero_to_three_years',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '124',
        status: 'pending',
        location: 'Kitchen',
        category: 'Appliances',
        deficiency: 'Range',
        reviewerNotes: 'Budget to replace range (2008) with stainless steel',
        solutions: [
          {
            uuid: '224',
            category: 'Appliances',
            solution: 'Range',
            description: 'Budget to replace range (2008) with stainless steel',
            unitOfMeasurement: 'EA',
            unitPrice: 990,
            quantity: 1,
            urgency: 'zero_to_three_years',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '125',
        status: 'pending',
        location: 'Kitchen',
        category: 'Appliances',
        deficiency: 'Refrigerator',
        reviewerNotes: 'Budget to replace refrigerator with stainless steel',
        solutions: [
          {
            uuid: '225',
            category: 'Appliances',
            solution: 'Refrigerator',
            description: 'Budget to replace refrigerator with stainless steel',
            unitOfMeasurement: 'EA',
            unitPrice: 1425,
            quantity: 1,
            urgency: 'zero_to_three_years',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '126',
        status: 'pending',
        location: 'Interior',
        category: 'Other',
        deficiency: 'Dryer Vent',
        reviewerNotes: 'Clean dryer vent',
        solutions: [
          {
            uuid: '226',
            category: 'Other',
            solution: 'Dryer Vent',
            description: 'Clean dryer vent',
            unitOfMeasurement: 'EA',
            unitPrice: 75,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '127',
        status: 'pending',
        location: 'Exterior',
        category: 'Decks & Porches',
        deficiency: 'Front Porch',
        reviewerNotes: 'Repair front steps. Replace damaged wrought iron rail.',
        solutions: [
          {
            uuid: '227',
            category: 'Decks & Porches',
            solution: 'Front Porch',
            description:
              'Repair front steps. Replace damaged wrought iron rail.',
            unitOfMeasurement: 'EA',
            unitPrice: 950,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '128',
        status: 'pending',
        location: 'Exterior',
        category: 'Landscaping & Irrigation',
        deficiency: 'Exterior Landscaping',
        reviewerNotes: 'Medium landscape service',
        solutions: [
          {
            uuid: '228',
            category: 'Landscaping & Irrigation',
            solution: 'Exterior Landscaping',
            description: 'Medium landscape service',
            unitOfMeasurement: 'EA',
            unitPrice: 550,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '129',
        status: 'pending',
        location: 'Exterior',
        category: 'Other Exterior',
        deficiency: 'Shed',
        reviewerNotes: 'Replace damaged door. Paint exterior of shed.',
        solutions: [
          {
            uuid: '229',
            category: 'Other Exterior',
            solution: 'Shed',
            description: 'Replace damaged door. Paint exterior of shed.',
            unitOfMeasurement: 'EA',
            unitPrice: 900,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '130',
        status: 'pending',
        location: 'Garage',
        category: 'Roof',
        deficiency: 'Garage Roof',
        reviewerNotes: 'Repair garage roof (hole in ceiling)',
        solutions: [
          {
            uuid: '230',
            category: 'Roof',
            solution: 'Garage Roof',
            description: 'Repair garage roof (hole in ceiling)',
            unitOfMeasurement: 'EA',
            unitPrice: 400,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
      {
        uuid: '131',
        status: 'pending',
        location: 'Exterior',
        category: 'Fences & Gates',
        deficiency: 'Fence',
        reviewerNotes:
          'Replace damaged chain link fence with wood privacy fence',
        solutions: [
          {
            uuid: '231',
            category: 'Fences & Gates',
            solution: 'Fence',
            description:
              'Replace damaged chain link fence with wood privacy fence',
            unitOfMeasurement: 'EA',
            unitPrice: 2000,
            quantity: 1,
            urgency: 'first_turn',
            adjustment: 1.0,
            itemCode: '',
          },
        ],
        media: [],
      },
    ],
    assets: [
      {
        uuid: '13513513673',
        sowProjectUuid: '1234-5678-9012-3457',
        status: 'in_progress',
        location: 'Kitchen',
        category: 'Appliances',
        item: 'Dishwasher',
        month: 11,
        year: 2021,
        media: [],
        endOfLifeDate: new Date('2023-12-31'),
      },
      // Make one for the range and refrigerator
      {
        uuid: '13513513674',
        sowProjectUuid: '1234-5678-9012-3457',
        status: 'in_progress',
        location: 'Kitchen',
        category: 'Appliances',
        item: 'Range',
        month: 11,
        year: 2008,
        media: [],
        endOfLifeDate: new Date('2023-12-31'),
      },
      {
        uuid: '13513513675',
        sowProjectUuid: '1234-5678-9012-3457',
        status: 'in_progress',
        location: 'Kitchen',
        category: 'Appliances',
        item: 'Refrigerator',
        month: 11,
        year: 2014,
        media: [],
        endOfLifeDate: new Date('2023-12-31'),
      },
    ],
  },
]
