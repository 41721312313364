import { useMutation } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'
import {
  AdminCreateScopeOfWorkItemDocument,
  AdminCreateScopeOfWorkItemSolutionDocument,
  AdminCreateScopeOfWorkMediaDocument,
  AdminDeleteScopeOfWorkItemDocument,
  AdminDeleteScopeOfWorkItemSolutionDocument,
  AdminDeleteScopeOfWorkMediaDocument,
  AdminUpdateScopeOfWorkItemDocument,
  AdminUpdateScopeOfWorkItemSolutionDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useSnackbar } from '@flock/shared-ui'
import { useEffect, useState } from 'react'
import { SowItemSolution } from '../individualSowProjectPageTypes'
import usePhotoGallery from '../PhotoGallery/usePhotoGallery'
import { useSowSaveContext } from '../SowSaveContextProvider'
import {
  SowDeficiencyModalProps,
  SowDeficiencyModalPresentationalProps,
} from './sowDeficiencyModalTypes'

const useSowDeficiencyModal: (
  props: SowDeficiencyModalProps
) => SowDeficiencyModalPresentationalProps = (
  props: SowDeficiencyModalProps
) => {
  const { deficiency, onClose, projectUUID } = props

  const [category, setCategory] = useState(deficiency?.category || '')
  const [location, setLocation] = useState(deficiency?.location || '')
  const [deficiencyText, setDeficiencyText] = useState(
    deficiency?.deficiency || ''
  )
  const [notesForReviewer, setNotesForReviewer] = useState(
    deficiency?.reviewerNotes || ''
  )

  const [status, setStatus] = useState(deficiency?.status || 'in_progress')

  const [loading, setLoading] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const [currentSolutions, setCurrentSolutions] = useState<SowItemSolution[]>([
    {
      uuid: '',
      category: '',
      solution: '',
      description: '',
      unitOfMeasurement: 'EA',
      unitPrice: 0,
      quantity: 0,
      urgency: 'first_turn',
      adjustment: 1.0,
      itemCode: '',
    },
  ])

  const {
    photos,
    selectedPhoto,
    photosToAdd,
    setPhotos,
    setSelectedPhoto,
    onAddPhotos,
    onDeletePhoto,
    setPhotosToAdd,
  } = usePhotoGallery()

  const { setSaveLoading, onSaveSuccess, onSaveError, refetchProject } =
    useSowSaveContext()
  const { notify } = useSnackbar()

  const [updateSOWItem] = useMutation(AdminUpdateScopeOfWorkItemDocument)
  const [createSOWItem] = useMutation(AdminCreateScopeOfWorkItemDocument)
  const [deleteSOWItem] = useMutation(AdminDeleteScopeOfWorkItemDocument)
  const [createSOWMedia] = useMutation(AdminCreateScopeOfWorkMediaDocument)
  const [deleteSOWMedia] = useMutation(AdminDeleteScopeOfWorkMediaDocument)
  const [createSOWItemSolution] = useMutation(
    AdminCreateScopeOfWorkItemSolutionDocument
  )
  const [updateSOWItemSolution] = useMutation(
    AdminUpdateScopeOfWorkItemSolutionDocument
  )
  const [deleteSOWItemSolution] = useMutation(
    AdminDeleteScopeOfWorkItemSolutionDocument
  )

  useEffect(() => {
    if (deficiency) {
      // Set the current solutions to a deep copy of the deficiency solutions
      const newSolutions = JSON.parse(JSON.stringify(deficiency.solutions))
      setCurrentSolutions(newSolutions)

      // Set the status
      setStatus(deficiency.status)

      // Set the photos
      const newPhotos = deficiency.media.map((photo) => ({
        url: photo.s3Url,
        addPhotosIndex: -1,
      }))
      setPhotos(newPhotos)
      setSelectedPhoto(
        newPhotos.length > 0 ? newPhotos[0] : { url: '', addPhotosIndex: -1 }
      )
    } else {
      setCurrentSolutions([
        {
          uuid: '',
          category: '',
          solution: '',
          description: '',
          unitOfMeasurement: 'EA',
          unitPrice: 0,
          quantity: 0,
          urgency: 'first_turn',
          adjustment: 1.0,
          itemCode: '',
        },
      ])
      setStatus('in_progress')
      setPhotos([])
      setSelectedPhoto({ url: '', addPhotosIndex: -1 })
      setCategory('')
      setLocation('')
      setDeficiencyText('')
      setNotesForReviewer('')
    }
    setPhotosToAdd([])
  }, [deficiency, setPhotos, setSelectedPhoto, setPhotosToAdd])

  const onAddSolution = () => {
    const newSolutions = [
      ...currentSolutions,
      {
        uuid: '',
        category: '',
        solution: '',
        description: '',
        unitOfMeasurement: 'EA',
        unitPrice: 0,
        quantity: 0,
        urgency: 'first_turn',
        adjustment: 1.0,
        itemCode: '',
      },
    ]
    setCurrentSolutions(newSolutions)
  }

  const onRemoveSolution = (index: number) => {
    const newSolutions = [...currentSolutions]
    newSolutions.splice(index, 1)
    setCurrentSolutions(newSolutions)
  }

  const onUpdateSolution = (index: number, solution: SowItemSolution) => {
    const newSolutions = [...currentSolutions]
    newSolutions[index] = solution
    setCurrentSolutions(newSolutions)
  }

  const onSave = async () => {
    setSaveLoading(true)
    setLoading(true)
    let deficiencyUuid = deficiency?.uuid || ''

    try {
      if (!deficiency?.uuid) {
        const resp = await createSOWItem({
          variables: {
            input: {
              sowItem: {
                uuid: uuidv4(),
                sowProjectUuid: projectUUID || '',
                category,
                deficiency: deficiencyText,
                itemLocation: location,
                reviewerNotes: notesForReviewer,
                itemStatus: status,
              },
            },
          },
        })
        deficiencyUuid = resp.data?.createSOWItem?.uuid || ''
      } else {
        await updateSOWItem({
          variables: {
            input: {
              uuid: deficiency.uuid,
              itemStatus: status,
              deficiency: deficiencyText,
              itemLocation: location,
              reviewerNotes: notesForReviewer,
              category,
            },
          },
        })
      }

      // For each solution, if there's no UUID, create the solution
      for (let i = 0; i < currentSolutions.length; i += 1) {
        if (!currentSolutions[i].uuid) {
          await createSOWItemSolution({
            variables: {
              input: {
                sowItemSolution: {
                  uuid: uuidv4(),
                  sowItemUuid: deficiencyUuid,
                  category: currentSolutions[i].category,
                  solution: currentSolutions[i].solution,
                  itemSolutionDescription: currentSolutions[i].description,
                  unitOfMeasurement: currentSolutions[i].unitOfMeasurement,
                  unitCost: currentSolutions[i].unitPrice,
                  quantity: currentSolutions[i].quantity,
                  adjustment: currentSolutions[i].adjustment,
                  acquisitionScopeCategory: currentSolutions[i].urgency,
                  costbookItemCode: currentSolutions[i].itemCode,
                },
              },
            },
          })
        } else {
          await updateSOWItemSolution({
            variables: {
              input: {
                uuid: currentSolutions[i].uuid,
                category: currentSolutions[i].category,
                solution: currentSolutions[i].solution,
                itemSolutionDescription: currentSolutions[i].description,
                unitOfMeasurement: currentSolutions[i].unitOfMeasurement,
                unitCost: currentSolutions[i].unitPrice,
                quantity: currentSolutions[i].quantity,
                adjustment: currentSolutions[i].adjustment,
                acquisitionScopeCategory: currentSolutions[i].urgency,
                costbookItemCode: currentSolutions[i].itemCode,
              },
            },
          })
        }
      }
      // For all existing solutions that are no longer in the currentSolutions, delete them
      if (deficiency) {
        for (let i = 0; i < deficiency.solutions.length; i += 1) {
          if (
            !currentSolutions.find(
              (solution) => solution.uuid === deficiency.solutions[i].uuid
            )
          ) {
            await deleteSOWItemSolution({
              variables: {
                input: {
                  uuid: deficiency.solutions[i].uuid,
                },
              },
            })
          }
        }
      }

      // For photos, iterate through the photos array. If the photo is in the deficiency media, do nothing. If it's not, upload the photo using the photosToAdd array.
      // If it's in the deficiency media but not in the photos array, delete the photo.
      if (deficiency) {
        for (let i = 0; i < deficiency.media.length; i += 1) {
          if (
            !photos.find((photo) => photo.url === deficiency.media[i].s3Url)
          ) {
            await deleteSOWMedia({
              variables: {
                input: {
                  uuid: deficiency.media[i].uuid,
                },
              },
            })
          }
        }
      }

      // Upload all of the photosToAdd
      for (let i = 0; i < photosToAdd.length; i += 1) {
        await createSOWMedia({
          variables: {
            input: {
              sowItemUuid: deficiencyUuid || '',
              file: photosToAdd[i],
              mediaType: photosToAdd[i].type,
            },
          },
        })
      }

      await refetchProject()

      notify('Successfully saved deficiency', 'success')
      onSaveSuccess()
      onClose()
    } catch (e) {
      notify(`Error saving deficiency: ${e.message}`, 'error')
      onSaveError(`Failed to save deficiency: ${e.message}`)
    }
    setLoading(false)
  }

  const onDelete = async () => {
    setSaveLoading(true)
    setLoading(true)
    try {
      if (deficiency) {
        await deleteSOWItem({
          variables: {
            input: {
              uuid: deficiency.uuid,
            },
          },
        })

        await refetchProject()

        notify('Successfully deleted deficiency', 'success')
        onSaveSuccess()
        onClose()
      } else {
        onClose()
      }
    } catch (e) {
      notify(`Error deleting deficiency: ${e.message}`, 'error')
      onSaveError(`Failed to delete deficiency: ${e.message}`)
    }
    setLoading(false)
  }

  return {
    ...props,
    category,
    setCategory,
    location,
    setLocation,
    deficiencyText,
    setDeficiencyText,
    notesForReviewer,
    setNotesForReviewer,

    status,
    setStatus,
    photos,
    photosToAdd,
    selectedPhoto,
    setSelectedPhoto,
    onAddSolution,
    currentSolutions,
    onAddPhotos,
    onDeletePhoto,

    onRemoveSolution,
    onUpdateSolution,

    onSave,
    setShowDeleteModal,
    showDeleteModal,
    loading,
    onDelete,
  }
}

export default useSowDeficiencyModal
