import { RouteComponentProps } from '@reach/router'

export type PrintCollateralPageProps = RouteComponentProps

export type CheckboxOption = {
  id: string
  label: string
  checked: boolean
}

export enum CollateralType {
  PORTFOLIO = 'portfolio',
  PORTFOLIO_BREAKDOWN = 'portfolio_breakdown',
  SCENARIOS = 'scenarios',
}

export type FormValues = {
  checkboxes: CheckboxOption[]
  scenario: string
  coverName: string
  collateralType: CollateralType
}
