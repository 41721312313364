import React from 'react'
import { Controller, FieldError } from 'react-hook-form'

import {
  Box,
  Button,
  ThemeProvider,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  styled,
} from '@mui/material'
import {
  Dropdown,
  FormattedTextField,
  flockSquareTheme,
  LibraryThemeProvider,
  Checkbox,
} from '@flock/shared-ui'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import usePrintCollateralPage from './usePrintCollateralPage'
import {
  PrintCollateralPageProps,
  CollateralType,
} from './printCollateralPageTypes'

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  borderRadius: '0',
  border: '0px solid',
  padding: '8px 12px',
  fontSize: '14px',
  color: theme.palette.moneyGreen.main,
  backgroundColor: theme.palette.green0.main,
  '&.Mui-selected': {
    backgroundColor: theme.palette.green2.main,
  },
  '&:hover': {
    backgroundColor: theme.palette.green5.main,
    color: 'white',
  },
  '&.MuiToggleButtonGroup-grouped': {
    // removes border from left of the buttons
    borderLeft: '0px solid',
    '&:not(:first-of-type)': {
      borderLeft: '0px solid',
    },
  },
}))

const PrintCollateralPage = (props: PrintCollateralPageProps) => {
  const {
    accountId,
    scenarios,
    loading,
    formControl,
    onSubmit,
    errors,
    handleDragEnd,
    checkboxFields,
    accountName,
  } = usePrintCollateralPage(props)

  if (loading) {
    return <Typography>Loading...</Typography>
  }

  return (
    <ThemeProvider theme={flockSquareTheme}>
      <LibraryThemeProvider theme={flockSquareTheme}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          p="24px"
          height="100vh"
        >
          <Box
            width="560px"
            height="756px"
            display="flex"
            flexDirection="column"
            gap="24px"
          >
            <Box display="flex" flexDirection="column" gap="8px">
              <Typography variant="h3" color="moh">
                Print Collateral
              </Typography>
              <Typography variant="p2" color="green4.main">
                Account Name: {accountName}
              </Typography>
              <Typography variant="p2" color="green4.main">
                Salesforce Account ID: {accountId}
              </Typography>
            </Box>

            <form onSubmit={onSubmit}>
              <Box display="flex" flexDirection="column" gap="16px">
                <Box>
                  <Controller
                    control={formControl}
                    name="scenario"
                    rules={{ required: 'Please select a scenario' }}
                    render={({ field: { ref: _ref, ...fieldProps } }) => (
                      <Dropdown
                        {...fieldProps}
                        label="Choose a scenario"
                        options={scenarios}
                        error={!!errors.scenario}
                        helperText={(errors.scenario as FieldError)?.message}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Controller
                    control={formControl}
                    name="coverName"
                    defaultValue=""
                    rules={{ required: 'Cover Name is required' }}
                    render={({ field: { ref: _ref, ...fieldProps } }) => (
                      <FormattedTextField
                        {...fieldProps}
                        sx={{ width: '100%' }}
                        label="Cover Name"
                        error={!!errors.coverName}
                        helperText={errors.coverName?.message}
                      />
                    )}
                  />
                </Box>
                <Box display="flex" flexDirection="column" gap="8px">
                  <Box>
                    <Controller
                      control={formControl}
                      name="collateralType"
                      rules={{ required: 'Please select a collateral type' }}
                      render={({
                        field: { ref: _ref, onChange, ...fieldProps },
                      }) => (
                        <ToggleButtonGroup
                          {...fieldProps}
                          onChange={(_, newValue) => onChange(newValue)}
                          exclusive
                          sx={{ gap: '8px' }}
                        >
                          <StyledToggleButton value={CollateralType.PORTFOLIO}>
                            Portfolio
                          </StyledToggleButton>
                          <StyledToggleButton
                            value={CollateralType.PORTFOLIO_BREAKDOWN}
                          >
                            Portfolio Breakdown
                          </StyledToggleButton>
                          <StyledToggleButton value={CollateralType.SCENARIOS}>
                            Scenarios
                          </StyledToggleButton>
                        </ToggleButtonGroup>
                      )}
                    />
                  </Box>
                  <Box>
                    {errors.collateralType && (
                      <Typography color="error">
                        {errors.collateralType.message}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box>
                  <Typography variant="h6">Reorderable Checkboxes</Typography>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="checkboxes">
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {checkboxFields.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(draggableProvided) => (
                                <Box
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  {...draggableProvided.dragHandleProps}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: 8,
                                    ...draggableProvided.draggableProps.style,
                                  }}
                                >
                                  <Controller
                                    name={`checkboxes.${index}.checked`}
                                    control={formControl}
                                    render={({
                                      field: { ref: _ref, ...fieldProps },
                                    }) => (
                                      <Checkbox
                                        {...fieldProps}
                                        label={item.label}
                                        checked={fieldProps.value}
                                      />
                                    )}
                                  />
                                </Box>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Box>

                <Button type="submit">Submit</Button>
              </Box>
            </form>
          </Box>
        </Box>
      </LibraryThemeProvider>
    </ThemeProvider>
  )
}

export default PrintCollateralPage
