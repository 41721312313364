import React from 'react'
import {
  Box,
  Modal,
  Typography,
  Autocomplete,
  TextField,
  CircularProgress,
} from '@mui/material'
import { Flag } from '@mui/icons-material'
import {
  CircleCheckedIcon,
  CircleUncheckedIcon,
  TrackedButton,
} from '@flock/shared-ui'
import SowModalSolutionRow from './SowModalSolutionRow'
import useSowDeficiencyModal from './useSowDeficiencyModal'
import { SowDeficiencyModalProps } from './sowDeficiencyModalTypes'
import { SowItemSolution } from '../individualSowProjectPageTypes'
import PhotoGallery from '../PhotoGallery/PhotoGallery'
import LabelledField from '../LabelledField'
import StatusButton from '../StatusButton'

const SowDeficiencyModal = (props: SowDeficiencyModalProps) => {
  const {
    open,
    locationOptions,
    categoryOptions,
    onClose,

    category,
    setCategory,
    location,
    setLocation,
    deficiencyText,
    setDeficiencyText,
    notesForReviewer,
    setNotesForReviewer,

    setStatus,
    status,
    photos,

    selectedPhoto,
    setSelectedPhoto,
    onAddPhotos,
    onDeletePhoto,

    currentSolutions,
    costCatalog,

    onAddSolution,
    onRemoveSolution,
    onUpdateSolution,

    loading,
    onSave,
    setShowDeleteModal,
    showDeleteModal,
    onDelete,
  } = useSowDeficiencyModal(props)

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          display="flex"
          flexDirection="column"
          gap="24px"
          position="absolute"
          p="16px"
          sx={{
            top: '2.5vh',
            left: '50%',
            transform: 'translate(-50%, 0)',
            width: '95vw',
            maxHeight: '95vh',
            backgroundColor: 'gray1.main',
            borderRadius: '8px',
            overflowY: 'auto',
          }}
        >
          {/* Deficiency Section */}
          <Box display="flex" gap="24px">
            {/* Deficiency Inputs */}
            <Box display="flex" flexDirection="column" width="100%" gap="16px">
              <Box display="flex" gap="16px" width="100%">
                <LabelledField label="Location">
                  <Autocomplete
                    onChange={(_, value) => setLocation(value)}
                    onInputChange={(_, value) => setLocation(value)}
                    value={location}
                    options={locationOptions}
                    disableClearable
                    freeSolo
                    placeholder="Location"
                    renderInput={(params) => (
                      <TextField {...params} variant="filled" fullWidth />
                    )}
                  />
                </LabelledField>
                <LabelledField label="Category">
                  <Autocomplete
                    onChange={(_, value) => setCategory(value)}
                    onInputChange={(_, value) => setCategory(value)}
                    value={category}
                    options={categoryOptions}
                    disableClearable
                    freeSolo
                    placeholder="Category"
                    renderInput={(params) => (
                      <TextField {...params} variant="filled" fullWidth />
                    )}
                  />
                </LabelledField>
              </Box>
              <LabelledField label="Deficiency">
                <TextField
                  onChange={(e) => setDeficiencyText(e.target.value)}
                  value={deficiencyText}
                  variant="filled"
                  placeholder="Deficiency"
                />
              </LabelledField>
              <LabelledField label="Notes for Reviewer">
                <TextField
                  onChange={(e) => setNotesForReviewer(e.target.value)}
                  value={notesForReviewer}
                  variant="filled"
                  multiline
                  rows={2}
                  placeholder="Notes for Reviewer"
                />
              </LabelledField>
              <Box display="flex" justifyContent="space-between">
                <Box
                  p="8px 12px"
                  borderRadius="8px"
                  sx={{
                    backgroundColor: 'gray2.main',
                  }}
                >
                  <Typography variant="c1">
                    {currentSolutions.length}{' '}
                    {currentSolutions.length === 1 ? 'solution' : 'solutions'}
                  </Typography>
                </Box>
                <Box display="flex" gap="8px">
                  <StatusButton
                    label="FLAG"
                    selected={status === 'flagged'}
                    icon={
                      <Flag
                        sx={{ width: '16px', height: '16px' }}
                        color="moneyGreen"
                      />
                    }
                    onClick={() => {
                      setStatus('flagged')
                    }}
                  />
                  <StatusButton
                    label="WIP"
                    selected={status === 'in_progress'}
                    icon={<CircleUncheckedIcon width="16px" height="16px" />}
                    onClick={() => {
                      setStatus('in_progress')
                    }}
                  />
                  <StatusButton
                    label="DONE"
                    selected={status === 'completed'}
                    icon={<CircleCheckedIcon width="16px" height="16px" />}
                    onClick={() => {
                      setStatus('completed')
                    }}
                  />
                </Box>
              </Box>
            </Box>

            {/* Image Viewer */}
            <PhotoGallery
              photos={photos}
              selectedPhoto={selectedPhoto}
              setSelectedPhoto={setSelectedPhoto}
              onAddPhotos={onAddPhotos}
              onDeletePhoto={onDeletePhoto}
              onClose={onClose}
            />
          </Box>
          {/* Solution Section */}
          <Box display="flex" flexDirection="column" gap="24px">
            {currentSolutions.map((solution, idx) => (
              <SowModalSolutionRow
                // eslint-disable-next-line react/no-array-index-key
                key={`${solution.uuid} + ${idx}`}
                {...solution}
                onRemoveSolution={() => onRemoveSolution(idx)}
                onUpdateSolution={(updatedSolution: SowItemSolution) =>
                  onUpdateSolution(idx, updatedSolution)
                }
                costCatalog={costCatalog}
              />
            ))}
          </Box>
          <Box width="100%">
            <TrackedButton
              size="smallForm"
              variant="secondary"
              onClick={onAddSolution}
            >
              Add Solution
            </TrackedButton>
          </Box>

          <Box display="flex" justifyContent="flex-end" gap="16px">
            <TrackedButton
              size="smallForm"
              // @ts-ignore
              variant="error"
              sx={{ width: '88px' }}
              disabled={loading}
              onClick={() => setShowDeleteModal(true)}
            >
              Delete
            </TrackedButton>
            <TrackedButton
              size="smallForm"
              variant="primary"
              disabled={loading}
              onClick={onSave}
              sx={{ width: '88px' }}
            >
              {loading ? (
                <CircularProgress size="16px" color="white" />
              ) : (
                'Save'
              )}
            </TrackedButton>
          </Box>
        </Box>
      </Modal>
      <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <Box
          position="absolute"
          width="480px"
          p="16px"
          sx={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'gray1.main',
            borderRadius: '8px',
          }}
        >
          <Box display="flex" flexDirection="column" gap="32px">
            <Typography variant="cta2">
              Are you sure you want to delete this deficiency?
            </Typography>
            <Box display="flex" justifyContent="flex-end" gap="16px">
              <TrackedButton
                size="smallForm"
                variant="secondary"
                disabled={loading}
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </TrackedButton>
              <TrackedButton
                size="smallForm"
                // @ts-ignore
                variant="error"
                disabled={loading}
                onClick={onDelete}
              >
                {loading ? (
                  <CircularProgress size="16px" color="white" />
                ) : (
                  'Delete'
                )}
              </TrackedButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default SowDeficiencyModal
