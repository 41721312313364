import { useMutation } from '@apollo/client'
import { AdminCreateScopeOfWorkProjectDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import { TrackedButton, useSnackbar, Dropdown } from '@flock/shared-ui'
import { Box, TextField, CircularProgress, Typography } from '@mui/material'
import React, { useState } from 'react'
import { LabelValueOption } from './IndividualSowProjectPage/individualSowProjectPageTypes'
import LabelledField from './IndividualSowProjectPage/LabelledField'

type CreateSowSubProjectModuleProps = {
  parentProjectUuid: string
  unitOptions: LabelValueOption[]
  costbookUuid: string
  parentProjectType: string
  inspectionDate?: Date | null | undefined
  refetch: () => Promise<any>
}

const CreateSowSubProjectModule = (props: CreateSowSubProjectModuleProps) => {
  const {
    parentProjectUuid,
    unitOptions,
    costbookUuid,
    parentProjectType,
    inspectionDate,
    refetch,
  } = props

  const [projectId, setProjectId] = useState('')
  const [selectedUnit, setSelectedUnit] = useState<string>()
  const [loading, setLoading] = useState(false)

  const [createSowProject] = useMutation(AdminCreateScopeOfWorkProjectDocument)

  const { notify } = useSnackbar()

  const onSubmit = async () => {
    setLoading(true)

    const inputObj = {
      parentProjectUuid,
      addressId: selectedUnit as string,
      externalProjectId: projectId,
      externalProjectType: parentProjectType,
      isMultifamily: false,
      costbook: costbookUuid,
      inspectionDate,
    }

    try {
      await createSowProject({
        variables: {
          input: inputObj,
        },
      })

      notify('Unit created successfully', 'success')

      await refetch()
    } catch (e) {
      notify(
        `Failed to create project. Please verify Address ID and Project ID ${e.message}`,
        'error'
      )
    }
    setLoading(false)
  }

  return (
    <Box
      display="flex"
      gap="16px"
      padding="16px"
      borderRadius="16px"
      sx={{
        backgroundColor: 'gray1.main',
      }}
    >
      <LabelledField label="Unit" width="100%">
        <Dropdown
          label=""
          variant="filled"
          options={unitOptions}
          value={selectedUnit}
          fullWidth
          onChange={(e) => setSelectedUnit(e.target.value)}
        />
      </LabelledField>
      {parentProjectType === 'inside_maps' && (
        <LabelledField label="Project ID">
          <TextField
            variant="filled"
            value=""
            onChange={(e) => setProjectId(e.target.value)}
          />
        </LabelledField>
      )}
      <LabelledField label=" " width="136px">
        <TrackedButton
          onClick={onSubmit}
          disabled={loading}
          sx={{
            width: '136px',
          }}
        >
          {loading ? (
            <CircularProgress size="28px" />
          ) : (
            <Typography variant="ctam" noWrap>
              Add Project
            </Typography>
          )}
        </TrackedButton>
      </LabelledField>
    </Box>
  )
}

export default CreateSowSubProjectModule

CreateSowSubProjectModule.defaultProps = {
  inspectionDate: undefined,
}
